/****
 *
 * containers
 *
****/

.wrapper {
	max-width: 1300px;
	width: 90%;
	margin: 0 auto;
}
.full-width {
	.wrapper {
		width: 100%;
		max-width: 100%;
	}
}
.overflow-ctn {
	overflow: hidden;
}
.ctn-1180 {

	.wrapper {
		max-width: 1180px;
	}
}

.ctn-980 {

	.wrapper {
		max-width: 980px;
	}
}

.white-ctn {
	background-color: map-get($map: $colors, $key: white);

	h1,
	.heading-1,
	h2,
	.heading-2,
	h3,
	.heading-3,
	h4,
	.heading-4,
	h5,
	.heading-5,
	h6,
	.heading-6 {
		color: map-get($map: $colors, $key: black);

	}

	p,
	ul li,
	span {
		color: map-get($map: $colors, $key: black50);

	}
}
