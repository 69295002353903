.slide-thumb img,
.burger-single,
.post-tags,
.post-content-des p,
.post-meta,
.search-button,
.single-blog-post,
.wpcf7-submit,
a,
a:visited,
button,
input[type="submit"],
.home-hero,
header {

	@include transition(all, 0.3s, ease-in-out);
}

.nav-container {
	display: flex;
	align-items: center;
}

header {

	&.nav-up {
		opacity: 0;
		visibility: hidden;
	}
}

.header-logo {
	display: flex;
	align-items: center;

	a {
		display: inline-block;
		line-height: 0.6;
	}

	.partner-logo {
		line-height: 0.6;
		margin-left: 20px;
	}
}


.no-overflow {
	overflow: hidden;
}

.site-logo {
	line-height: 0.65;
}

.logo {
	position: relative;
	z-index: 9;
	line-height: 0.65;
	width: 230px;
	transition: all 0.2s ease-in-out;

	a {
		margin-top: 3px;
		display: block;
		transform: scale(1);
		transform-origin: left top;
	}
}

.header-inner {
	padding: 20px 0;
	box-sizing: border-box;

	@include transition(all, 0.3s, ease-in-out);
}

header {
	z-index: 9;
	width: 100%;
	position: fixed;
	top: 30px;
}


.header-wrapper {
	box-sizing: border-box;
	width: 90%;
	max-width: 1304px;
	margin: 0 auto;
	background-color: map-get($map: $colors, $key: black70);
	backdrop-filter: blur(30px);
	padding: 0 10px 0 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-content: space-between;
}


.header-btns {
	margin-top: 0;
	z-index: 1;
	display: flex;
	align-items: center;

	a,
	a:visited {

		&.login-btn {
			font-size: 18px;
			line-height: 1.3;
			color: map-get($map: $colors, $key: white);

			@include font(gs, med, weight);
			border-radius: 8px;
			background-color: map-get($map: $colors, $key: m-gray);
			padding: 13px 20px 14px 20px;
			display: inline-block;
			margin-right: 16px;

			&:hover {
				background-color: map-get($map: $colors, $key: d-gray);
			}
		}

		&.button {
			padding: 13px 20px 14px 20px;
			font-size: 18px;
			line-height: 1.3;
		}
	}
}

body.logged-in.admin-bar {

	header {
		top: 32px;
	}

	&.hide-topbar {

		header {
			top: -12px;
		}
	}
}

.desktop-hide {
	display: none;
}
