/*
Theme Name: Roomcast
Theme URI: http://roomcastdesign.com
Author: Team Roomcast
Author URI: http://roomcastdesign.com/team
Description: WordPress theme by Roomcast
Version: 1.0
*/

/* ------------------------------------------------------------------
>>>                      TABLE OF CONTENTS:                      <<<
--------------------------------------------------------------------
> Variables
	>> Colors
	>> Columns
> Mixins
> Fonts
> Defaults
	>> Normalize
	>> Forms Defaults
	>> Clearings
> Typography
	>> Headings
	>> Copy
> Elements
	>> Lists
	>> Quotes
	>> Tables
	>> Images
	>> Links
> Forms
	>> Buttons
	>> Fields
	>> Form Elements
> Media
	>> Captions
	>> Galleries
> WordPress Core
	>> Accessibility
	>> Alignments
	>> Posts & Pages
	>> Pagination
	>> Comments
	>> Widgets
> Site
	>> Layout
		>>> Header
		>>> Footer
	>> Theme
	>> Blog
	>> Single
	>> Home Page
	>> About Us
	>> Contact Us
	>> Burgers Landing
	>> Single Burger
	>> Blocks
> Responsive
	>> Regular Queries
	>> Extra Queries
> Import Fonts
> Vendors
	> Owl Carousel
	> Slick Nav
------------------------------------------------------------------ */

/* ------------------------------------------------------------------
> Variables
------------------------------------------------------------------ */
@import "partials/variables/variables";

/* ------------------------------------------------------------------
> Mixins
------------------------------------------------------------------ */
@import "partials/mixins/mixins";

/* ------------------------------------------------------------------
> Fonts
------------------------------------------------------------------ */
@import "partials/fonts/fonts";

/* ------------------------------------------------------------------
> Defaults
------------------------------------------------------------------ */
@import "partials/defaults/defaults";

/* ------------------------------------------------------------------
> Typography
------------------------------------------------------------------ */
@import "partials/typography/typography";

/* ------------------------------------------------------------------
> Elements
------------------------------------------------------------------ */
@import "partials/elements/elements";

/* ------------------------------------------------------------------
> Forms
------------------------------------------------------------------ */
@import "partials/forms/forms";

/* ------------------------------------------------------------------
> Media
------------------------------------------------------------------ */
@import "partials/media/media";

/* ------------------------------------------------------------------
> Core
------------------------------------------------------------------ */
@import "partials/core/core";

/* ------------------------------------------------------------------
> Blocks
------------------------------------------------------------------ */
@import "partials/blocks/blocks";

/* ------------------------------------------------------------------
> Site
------------------------------------------------------------------ */
@import "partials/site/site";

/* ------------------------------------------------------------------
> Responsive
------------------------------------------------------------------ */
@import "partials/responsive/responsive";

/* ------------------------------------------------------------------
> Import Fonts
------------------------------------------------------------------ */
@import "partials/fonts/import-fonts";

/* ------------------------------------------------------------------
> Vendors CSS Starts Here
------------------------------------------------------------------ */

/* ------------------------------------------------------------------
> Vendors CSS Example
------------------------------------------------------------------ */
@import "vendors/owl.carousel.css";
@import "vendors/owl.theme.default.css";
@import "vendors/magnific-popup.min.css";
