.hero-section {
	transition: all 0.3s ease-in-out;
	padding-top: 100px;
	position: relative;

	&::before {
		content: "";
		background-image: url(../images/background-inner.webp);
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: 640px;
		z-index: -1;
		top: 0;
		right: 0;
	}

	&.home-hero-ctn {
		&::before {
			background-image: url(../images/background-home.webp);
			height: 960px;
			background-size: cover;
		}
	}
}

.hero-content-inner {
	max-width: 575px;
	margin-right: 15px;
	float: right;
}

.hero-ctn {
	padding: 70px 0 60px;

	&.serach-hero {
		padding-bottom: 60px;
	}
}

.hero-meeting-form-ctn {
	background-color: map-get($map: $colors, $key: white);
	border-radius: 10px;
	padding: 7px;
	max-width: 600px;
	margin: 50px auto 27px auto;
	box-sizing: border-box;
	form {
		margin-bottom: 0;
		display: flex;
		input[type="text"] {
			border: 0px;
			padding: 15px;
			width: 100%;
			margin-right: 10px;
			color: map-get($map: $colors, $key: black);
			margin-bottom: 0;
			font-size: 18px;
			&:focus {
				box-shadow: none;
			}
		}
		input[type="submit"] {
			padding: 13px 20px 14px 20px;
			font-size: 18px;
			line-height: 1.3;
			height: 50px;
			margin-bottom: 0;
			width: max-content;
		}
	}
	::input-placeholder {
		color: map-get($map: $colors, $key: l-tblack);
		font-size: 18px;
		@include font(gs, med);
	}

	::input-placeholder {
		font-size: 18px;
		color: map-get($map: $colors, $key: l-tblack);
	}

	::placeholder {
		font-size: 18px;
		color: map-get($map: $colors, $key: l-tblack);
	}
}
.hero-services-ctn {
	display: flex;
	gap: 30px;
	align-items: center;
	max-width: 600px;
	margin: 0 auto;
}

.hero-services-heading {
	@include font(gs, med);
	font-size: 18px;
	line-height: 1.4;
	color: map-get($map: $colors, $key: white);
	width: 135px;
	text-align: left;
}

.hero-services-main {
	width: calc(100% - 135px);
	gap: 14px;
	display: flex;
	a,
	a:visited {
		display: block;
		padding: 3px;
		border: 1px solid transparent;
		border-radius: 8px;
		line-height: 0.65;
		width: 100px;
		box-sizing: border-box;
		span.hero-service-image {
			display: block;
			img {
				border-radius: 6px;
			}
		}
		span.hero-service-icon {
			width: 36px;
			height: 36px;
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -18px;
		}
		&:hover {
			border-color: map-get($map: $colors, $key: white);
		}
	}
}

.hero-home {
	max-width: 715px;
	margin: 0 auto;
	position: relative;
	z-index: 1;

	h1 {
		margin-bottom: 30px;
	}
}

.hero-text {
	max-width: 614px;
	margin: 0 auto;

	p {
		margin: 0 0 40px;
		color: map-get($map: $colors, $key: white);

		@include font(gs, med, weight);

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.hero-jumplinks {
	display: flex;
	justify-content: center;
	margin-top: 40px;

	a,
	a:visited {
		&.jump-link {
			display: flex;
			padding: 11px 13px;
			border: 1px solid transparent;
			border-radius: 10px;
			text-align: left;
			align-items: center;
			margin-right: 26px;

			&:last-child {
				margin-right: 0;
			}

			.jumplink-img {
				line-height: 0.65;
			}

			.jumplink-text {
				line-height: 1.2;
				font-size: 18px;
				color: map-get($map: $colors, $key: white80);
				letter-spacing: 0.04em;
				margin-left: 20px;
				transition: all 0.3s ease-in-out;

				@include font(gs, med, weight);
			}

			&:hover {
				background-color: map-get($map: $colors, $key: white05);
				border: 1px solid map-get($map: $colors, $key: white20);

				.jumplink-text {
					color: map-get($map: $colors, $key: white);
				}
			}

			&:nth-child(1) {
				.jumplink-text {
					width: 84px;
				}
			}

			&:nth-child(2) {
				.jumplink-text {
					width: 115px;
				}
			}

			&:nth-child(3) {
				.jumplink-text {
					width: 102px;
				}
			}
		}
	}
}

.hero-button {
	margin-top: 70px;
}

.simple-hero {
	padding: 80px 0;
}

.toolkit-hero-ctn {
	h1 {
		margin-bottom: 20px;
	}

	span {
		color: map-get($map: $colors, $key: white50);

		@include font(gs, med, weight);
	}
}

.pricing-hero-content,
.extension-hero-content {
	justify-content: space-between;
	align-items: center;

	h1 {
		margin-bottom: 0;
	}
}

/* SWITCH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.switch-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
	background-color: map-get($map: $colors, $key: white10);
	border: 1px solid map-get($map: $colors, $key: white20);
	border-radius: 8px;

	label {
		margin: 0;
		padding: 0;

		&::before,
		&::after {
			display: none;
		}
	}

	[type="radio"]:not(:checked) + label {
		margin: 0;
	}

	[type="radio"]:checked + label {
		margin: 0;
	}
}

.toggle-buttons {
	margin-top: 3px;
}

.switch-wrapper [type="radio"] {
	position: absolute;
	left: -9999px;
	margin: 0;
	padding: 0;
}

.switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"],
.switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"] {
	color: map-get($map: $colors, $key: black) !important;
	background-color: map-get($map: $colors, $key: white) !important;
}

.switch-wrapper label {
	border-radius: 6px;
	padding: 6px 16px 7px 16px !important;
	line-height: 1.3 !important;
	cursor: pointer !important;
	font-size: 16px;
	color: map-get($map: $colors, $key: white) !important;
	@include font(gs, med);

	span {
		color: map-get($map: $colors, $key: green);
		@include font(gs, sbold);
	}
}

/* Solution Hero
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.solution-hero-heading-area {
	margin-bottom: 30px;
	align-items: center;

	h1 {
		margin-bottom: 0;
	}
}

.hero-icon {
	width: 72px;
	height: 72px;
	line-height: 0.65;
	margin-right: 30px;
}

.solution-hero-content {
	p {
		font-size: 22px;
		@include font(gs, med, weight);
		max-width: 615px;
		line-height: 1.4;
		color: map-get($map: $colors, $key: white);
		margin-bottom: 50px;
	}

	a,
	a:visited {
		&.down-arrow-btn {
			padding: 19px 50px 19px 20px;
			background-image: url(../images/hero-down-arrow.svg);
			background-repeat: no-repeat;
			background-position: right 24px center;
		}
	}
}

.solution-hero-video {
	position: relative;
	max-width: 880px;
	float: right;
	margin-top: -71px;

	img,
	video {
		border-radius: 10px;
	}
}

.video-area-ctn {
	position: relative;
	line-height: 0.65;
}

.play-button {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 1;

	a,
	a:visited {
		width: 100px;
		height: 100px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		border-bottom: 0px;
		border-color: transparent;
		color: transparent;
		padding: 0;

		&::before {
			content: "";
			border-radius: 50%;
			background-color: map-get($map: $colors, $key: white90);
			backdrop-filter: blur(2px);
			position: absolute;
			transition: all 0.3s ease-in-out;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
		}

		&:hover {
			&::before {
				transform: scale(1.1);
			}
		}

		img {
			position: absolute;
			width: 24px;
			height: 24px;
			left: 5px;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			border-radius: 0px;
			width: auto !important;
		}
	}
}
