/* -------------------------------------------------
>> Base Styles
---------------------------------------------------
>> Do not edit these styles
------------------------------------------------- */

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
	appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/**
 * Add the correct box sizing in IE 10.
 */

[type="checkbox"],
[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
	appearance: none;
}

[type="search"] {
	appearance: textfield;
	outline-offset: -2px;
}

::-webkit-file-upload-button {
	appearance: button;
	font: inherit;
}

.search-form {
	display: flex;
	justify-content: center;
	align-items: flex-start;

	input[type="submit"],
	button,
	input[type="button"],
	input[type="reset"] {
		margin: 0 0 10px 10px;
	}
}
