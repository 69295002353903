/*-------------------------------------------------
>> Base Styles
---------------------------------------------------
>> Do not edit these styles
-------------------------------------------------*/

embed,
iframe,
object {
	max-width: 100%;
}

/*-------------------------------------------------
>> Project Styles
---------------------------------------------------
>> Edit these styles as required
-------------------------------------------------*/
