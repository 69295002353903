.screencast-block {

	.section-head {

		h2 {
			padding-left: 102px;
			background-image: url(../images/screencast-icon-big.svg);
			background-repeat: no-repeat;
			background-position: top 5px left;
			margin-bottom: 0;
		}
	}
}

.sc-area {
	flex-direction: row-reverse;
	justify-content: space-between;
}

.sc-content {
	max-width: 478px;
}

.sc-head {

	p {
		margin-bottom: 60px;
		color: map-get($map: $colors, $key: black50);
		font-size: 24px;

		@include font(gs, med, weight);
	}
}

.sc-feature {
	display: flex;
	margin-bottom: 50px;
}

.sc-icon {
	background: linear-gradient(180deg, #eaeff3 0%, #d8dfe3 100%);
	border-radius: 10px;
	display: flex;
	align-items: center;
	margin-right: 30px;

	span {
		display: flex;
		width: 72px;
		height: 72px;
		align-items: center;
		justify-content: center;

		img {
			line-height: 0.65;
			max-width: 40px;
			max-height: 40px;
		}
	}
}

.sc-feature-content {

	span {
		color: map-get($map: $colors, $key: black);
		display: block;
		margin-bottom: 20px;
		font-size: 24px;
		line-height: 1.05;

		@include font (gs, med, weight);

	}

	p {
		font-size: 24px;
		line-height: 1.4;

		@include font (gs, med, weight);

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.screencast-animation {
	max-width: 600px;
	border-radius: 20px;
	line-height: 0.65;

	img {
		border-radius: 20px;
	}
}

.logos-ctn {
	margin-top: 80px;

	.owl-stage-outer {
		padding: 34px 0 40px 0;
	}
}

.logos-slider {
	-webkit-mask-image: linear-gradient(var(--mask-direction, to right), hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 10%, hsl(0 0% 0% / 1) 90%, hsl(0 0% 0% / 0));
}

.logo-slide {
	background: #fff;
	box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	width: 120px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 64px;
		max-height: 64px;
	}

	span {
		padding: 32px;
		border-bottom: 0;
		display: block;
		border-radius: 50%;
	}
}
