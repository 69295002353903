/*-------------------------------------------------
>> Accessibility styles for WordPress
-------------------------------------------------*/
@import "accessibility";

/*-------------------------------------------------
>> Default WordPress alignments
-------------------------------------------------*/
@import "alignments";

/*-------------------------------------------------
>> Styles for posts and pages
-------------------------------------------------*/
@import "posts-and-pages";

/*-------------------------------------------------
>> Pagination styles
-------------------------------------------------*/
@import "pagination";

/*-------------------------------------------------
>> Styles for WordPress comments
-------------------------------------------------*/
@import "comments";

/*-------------------------------------------------
>> Styles for default WordPress widgets
-------------------------------------------------*/
@import "widgets";
