.pricing-block-ctn {
	.three-columns {
		gap: 20px 20px;
		.column {
			width: calc(33.33% - 13.33px);
		}
	}
}

.recommended-heading {
	display: none;
	position: absolute;
	top: -36px;
	background-image: url(../images/recommended-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 8px 8px 0 0;
	color: map-get($map: $colors, $key: white);
	text-transform: uppercase;
	width: 100%;
	letter-spacing: 0.9px;
	font-size: 15px;
	@include font(gs, sbold, weight);
	padding: 7px 0 7px 0;
}

.pricing-column {
	background-color: map-get($map: $colors, $key: white);
	border-radius: 10px;
	color: map-get($map: $colors, $key: dgray);
	text-align: center;
	position: relative;

	&.recommended {
		border-radius: 0px 0px 10px 10px;

		.recommended-heading {
			display: block;
		}

		.table-button {
			a,
			a:visited {
				background-color: map-get($map: $colors, $key: dgray);
				&:hover {
					background-color: map-get($map: $colors, $key: black);
				}
			}
		}
	}
	&:nth-child(1) {
		.price-yealy-ctn .account-month {
			min-height: 36.38px;
		}
	}
}

.price {
	@include font(gs, med, weight);
}

.price-head {
	color: map-get($map: $colors, $key: dgray);
	font-size: 20px;
	line-height: 1.3;
	@include font(gs, sbold, weight);
	padding: 20px 40px;
	border-bottom: 1px solid map-get($map: $colors, $key: black15);
}

.orignal-price {
	font-size: 28px;
	line-height: 1.3;
	text-decoration: line-through;
	margin-bottom: 8px;
	color: map-get($map: $colors, $key: l-tblack);
}

.discounted-price {
	margin-bottom: 12px;
	color: map-get($map: $colors, $key: dgray);
}
.monthly .account-month {
	min-height: 36.38px;
}

.account-month,
.billed-yearly {
	font-size: 14px;
	color: map-get($map: $colors, $key: l-tblack);
	line-height: 1.3;
	max-width: 150px;
	margin: 0 auto;
}

.table-button {
	line-height: 0.65;
	margin-top: 5px;

	a,
	a:visited {
		font-size: 18px;
		padding: 13px 19px 14px 20px;
		border-radius: 8px;
		&.black {
			background-color: map-get($map: $colors, $key: dgray);
		}
	}
}

.table-text {
	padding: 20px;
	font-size: 18px;
	line-height: 1.3;
	@include font(gs, med, weight);
	color: map-get($map: $colors, $key: dgray);

	&.d-flex {
		justify-content: center;
		align-items: center;
		gap: 8px;
	}
	.minutes {
		span {
			display: inline-block;
		}
	}

	span {
		display: block;
	}

	.minutes {
		position: relative;

		.tooltip-icon {
			display: inline-block;
			margin-left: 8px;
			position: relative;
			top: 3px;
		}
	}

	.small-text {
		line-height: 1.3;
		margin-top: 8px;
	}
}

.minutes {
	max-width: max-content;
	margin: 0 auto;
}

.top-border {
	border-top: 1px solid map-get($map: $colors, $key: black15);
}

.tooltip-box {
	line-height: 0.65;
	position: relative;

	p {
		margin-bottom: 0;
	}
}

.table-lists-ctn {
	&.active {
		.tooltip-content {
			opacity: 1;
			visibility: visible;
		}

		.tooltip-box .tooltip-icon {
			opacity: 1;
		}
	}
}

.tooltip-standard {
	position: absolute;
	background-color: map-get($map: $colors, $key: white);
	padding: 12px;
	border-radius: 5px;
	width: 168px;
	box-sizing: border-box;
	text-align: left;
	box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
	bottom: 38px;
	left: 96px;
	z-index: 1;
	transition: 0.3s ease-in-out;
	opacity: 0;
	visibility: hidden;
	&.single-line-text {
		left: calc(100% - 33px);
	}

	p {
		margin-bottom: 0;
		font-size: 14px;
		@include font(gs, med, weight);
		line-height: 1.3;
		color: map-get($map: $colors, $key: dgray);
	}

	&::after {
		content: "";
		width: 1px;
		height: 12px;
		position: absolute;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #fff;
		transition: all 0.4s ease-in-out;
		bottom: -10px;
		z-index: 1;
		left: 12px;
	}
}

.minutes {
	.tooltip-icon {
		&:hover + .tooltip-standard {
			opacity: 1;
			visibility: visible;
		}
	}
}

.tooltip-content {
	position: absolute;
	background-color: map-get($map: $colors, $key: white);
	padding: 6px 6px 0 6px;
	border-radius: 5px;
	width: 193px;
	box-sizing: border-box;
	text-align: left;
	box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
	bottom: 30px;
	left: -13px;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s ease-in-out;

	&::after {
		content: "";
		width: 1px;
		height: 12px;
		position: absolute;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #fff;
		transition: all 0.4s ease-in-out;
		bottom: -10px;
		z-index: 1;
		left: 12px;
	}

	span {
		color: map-get($map: $colors, $key: white);
		display: block;
		padding: 3px 6px;
		border-radius: 3px;
		font-size: 14px;
		@include font(gs, med, weight);
		line-height: 1.3;
		margin-bottom: 12px;

		&.green {
			background-color: map-get($map: $colors, $key: green);
		}

		&.purple {
			background-color: map-get($map: $colors, $key: purple);
		}

		&.magenta {
			background-color: map-get($map: $colors, $key: magenta);
		}
	}

	ul {
		margin: 0 0 12px 0;
		padding: 0;
		list-style-type: none;

		li {
			font-size: 14px;
			padding: 0 0 0 6px;
			@include font(gs, med, weight);
			line-height: 1.3;
			margin-bottom: 4px;
			color: map-get($map: $colors, $key: dgray);

			line &::before {
				display: none;
			}
		}
	}

	a,
	a:visited {
		&.more-features {
			font-size: 14px;
			@include font(gs, med, weight);
			line-height: 1.3;
			padding: 0;
			display: block;
			margin-bottom: 12px;
			border-bottom: 0;
			margin-left: 5px;
		}
	}

	&.active {
		display: block;
	}
}

.tooltip-icon {
	line-height: 0.65;
	opacity: 0.5;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	width: 18px;
	height: 18px;
	display: inline-block;

	&:hover {
		opacity: 1;
	}
}

.price-notice-ctn {
	max-width: 580px;
	margin: 0 auto;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 10px;
	background-color: #505459;

	ul {
		margin: 0 0 0 3px;
		padding: 0;
		list-style-type: none;

		li {
			color: map-get($map: $colors, $key: white);
			font-size: 16px;
			line-height: 1.3;
			@include font(gs, med, weight);
			margin-bottom: 7px;

			&::before {
				background-color: map-get($map: $colors, $key: white);
				width: 5px;
				height: 5px;
				left: 7px;
				top: 8px;
			}

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

.notice-heading {
	color: map-get($map: $colors, $key: white);
	font-size: 16px;
	line-height: 1.3;
	@include font(gs, sbold, weight);
	margin-bottom: 13px;
}
