/* -------------------------------------------------
>> Project Styles
---------------------------------------------------
>> Edit these styles as required
------------------------------------------------- */

input[type="image"] {
	margin: 0 10px 0 0;
}

input[type="submit"],
button,
input[type="button"],
input[type="reset"] {

	@include transition(all, 0.4s, ease-in-out);
	@include font(gs, med);
	padding: 0 30px;
	display: inline-block;
	box-sizing: border-box;
	background-color: map-get($map: $colors, $key: blue);
	color: map-get($map: $colors, $key: white);
	border: none;
	font-size: 20px;
	line-height: 1.3;
	border-radius: 8px;
	height: 64px;
	outline: none;
	cursor: pointer;
	margin: 0;
	width: 100%;
}

input[type="submit"]:hover,
button:hover,
input[type="button"]:hover {
	color: map-get($map: $colors, $key: white);
	background-color: map-get($map: $colors, $key: d-blue);
}

input[type="reset"] {
	background-color: map-get($map: $base_colors, $key: theme_btn_bgcolor);
	color: map-get($map: $base_colors, $key: theme_btn_color);

	&:hover {
		color: map-get($map: $base_colors, $key: theme_btn_color);
		background-color: map-get($map: $base_colors, $key: theme_btn_bgcolor_hover);
	}
}
