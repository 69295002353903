.ts-160 {
	height: 160px;
}

.ts-140 {
	height: 140px;
}

.ts-120 {
	height: 120px;
}

.ts-100 {
	height: 100px;
}

.ts-80 {
	height: 80px;
}

.ts-60 {
	height: 60px;
}

.ts-40 {
	height: 40px;
}
