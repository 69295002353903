@media only screen and (min-width: 1921px) {
	.hero-section {
		&::before {
			background-size: 100% 100%;
		}

		&.home-hero-ctn {
			&::before {
				background-size: 100% 100%;
			}
		}
	}
}

@media only screen and (max-width: 1199px) {
	.hero-section {
		&::before {
			background-image: url(../images/inner-background-mobile.webp);
			height: 500px;
			background-size: 100% 100%;
		}

		&.home-hero-ctn {
			&::before {
				background-image: url(../images/home-background-mobile.webp);
				height: 780px;
				background-size: 100% 100%;
			}
		}
	}
	.for-desktop {
		display: none;
	}
	.for-mobile {
		display: block;
	}
}

@media only screen and (max-width: 1003px) {
	.hero-section {
		padding-top: 69px;
	}

	.site-logo {
		width: 140px;
	}

	.header-logo .partner-logo {
		margin-left: 18px;
		width: 81px;
	}

	.header-logo {
		position: relative;
		z-index: 10;
	}
}

@media only screen and (min-width: 748px) and (max-width: 1003px) {
	.footer-widgets.d-flex {
		display: flex;
		flex-wrap: wrap;
	}

	.single-widget {
		width: 48%;
		margin-right: 4%;

		&:first-child {
			width: 100%;
			margin: 0 0 64px 0;
		}
	}
}

@media only screen and (max-width: 747px) {
	.hero-section {
		&::before {
			height: 300px;
		}

		&.home-hero-ctn {
			&::before {
				height: 580px;
			}
		}
	}

	.footer-bottom.d-flex {
		display: block;
	}

	.footer-logo a {
		display: inline-block;
		max-width: 140px;
	}

	.footer-widgets.d-flex {
		display: flex;
		flex-wrap: wrap;
	}

	.footer-widgets .single-widget {
		width: 48%;
		margin-right: 4%;

		&:first-child {
			margin-bottom: 56px;
			width: 100%;
			margin-right: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		p {
			margin-bottom: 30px;
		}

		ul li {
			font-size: 16px;
		}
	}

	.footer-bottom {
		margin-top: 18px;
	}

	.copy-right {
		width: 100%;
		color: map-get($map: $colors, $key: white50);
		margin-bottom: 16px;
		font-size: 15px;
	}

	.legal-nav ul li {
		font-size: 15px;
		margin-right: 15px;
	}
}

@media only screen and (max-width: 459px) {
}

@media only screen and (max-width: 634px) {
}

@media only screen and (max-width: 345px) {
}
