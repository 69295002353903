.ai-features-block {

	.section-head {

		h2 {
			padding-left: 102px;
			background-image: url(../images/ai-icon-big.svg);
			background-repeat: no-repeat;
			background-position: top 5px left;
			margin-bottom: 0;
		}

		p {
			font-size: 24px;
			line-height: 1.4;
			max-width: 780px;
			margin-bottom: 0;

			@include font(gs, med, weight);
		}
	}
}

.ai-features-ctn {
	justify-content: space-between;
}

.ai-feature-content {
	max-width: 510px;
}

.ai-feature-animation {
	max-width: 505px;
}

.kicker {
	color: map-get($map: $colors, $key: yellow);
	line-height: 1.3;

	@include font(gs, sbold, weight);
}

.typing-text {
	min-height: 360px;

	p {
		color: map-get($map: $colors, $key: white);
		font-size: 32px;
		line-height: 1.4;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include font(gs, med, weight);
	}
}

@keyframes blink {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 0;
	}

	60% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


.cursor {
	animation: blink 1.3s infinite;
	position: relative;
	top: -3px;
}

.typed-cursor {
	margin-left: 5px;
	display: inline-block;
	line-height: 1;
}
