.footer-section {
	border-top: 1px solid map-get($map: $colors, $key: white10);
}

.footer-cta-ctn {
	border-bottom: 2px solid map-get($map: $colors, $key: white10);
}

.cta-heading {
	br {
		display: none;
	}

	h2 {
		margin-bottom: 0;
	}

	span {
		display: inline-block;
		line-height: 0.65;
		position: relative;
		margin: 0 4px;
		top: 3px;

		img {
			width: 55px;
		}
	}
}

.cta-button {
	line-height: 0.65;
}

.footer-logo {
	margin-bottom: 30px;
	line-height: 0.65;
	align-items: center;
}

.single-widget {
	width: 300px;
	margin-right: 40px;

	.address {
		max-width: 325px;
	}

	&:first-child {
		width: 458px;
	}

	&:last-child {
		margin-right: 0;
	}

	h4 {
		font-size: 18px;
		line-height: 130%;
		color: map-get($map: $colors, $key: white);
		margin-bottom: 30px;
	}

	p {
		font-size: 16px;
		margin-bottom: 30px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			font-size: 18px;
			line-height: 1.3;
			display: block;
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}

			a,
			a:visited {
				color: map-get($map: $colors, $key: white80);
				border-bottom: 1px solid transparent;

				&:hover {
					border-bottom: 1px solid map-get($map: $colors, $key: white80);
				}
			}
		}
	}
}

.social-icons {
	line-height: 0.65;
	margin-left: 40px;
	margin-top: 4px;

	a,
	a:visited {
		margin-right: 23px;
		border-radius: 4px;
		line-height: 0.65;
		width: 24px;
		height: 24px;
		background-color: map-get($map: $colors, $key: white);
		display: flex !important;
		align-items: center;
		justify-content: center;

		img {
			max-width: 14px;
		}

		&:hover {
			background-color: map-get($map: $colors, $key: blue);
		}
	}
}

.footer-bottom {
	align-items: center;
	position: relative;
	top: -5px;
}

.language-select {
	select {
		background-color: map-get($map: $colors, $key: black50);
		color: map-get($map: $colors, $key: white50);
		border-radius: 5px;
		@include font(gs, med);
		font-size: 14px;
		line-height: 1.3;
		letter-spacing: 0.84px;
		max-width: max-content;
		height: 32px;
		padding: 0 26px 0 10px;
		text-transform: uppercase;
		background-image: url(../images/footer-select-icon.svg);
		background-repeat: no-repeat;
		background-position: right 8px center;
	}
}

.copy-right {
	width: 458px;
	margin-right: 40px;
	color: map-get($map: $colors, $key: white80);
	font-size: 16px;
}

.legal-nav {

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			font-size: 16px;
			line-height: 1.3;
			display: inline-block;
			margin: 0 39px 0 0;

			&:last-child {
				margin: 0;
			}

			a,
			a:visited {
				color: map-get($map: $colors, $key: white50);
				border-bottom: 1px solid transparent;

				&:hover {
					border-bottom: 1px solid map-get($map: $colors, $key: white80);
					color: map-get($map: $colors, $key: white80);
				}
			}
		}
	}
}
