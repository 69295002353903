.faq-area {
	border-top: 1px solid map-get($map: $colors, $key: white20);
}

.faq {
	border-bottom: 1px solid map-get($map: $colors, $key: white20);

	> span {
		display: block;
		color: map-get($colors, white);
		padding: 30px 0;
		@include font(gs, sbold, weight);
		font-size: 22px;
		line-height: 1.3;
		cursor: pointer;
		position: relative;
		padding-right: 80px;

		@include transition(all, 0.4s, ease-in-out);

		&::after {
			position: absolute;
			content: "";
			width: 28px;
			height: 28px;
			right: 0;
			top: 31px;

			@include transition(all, 0.4s, ease-in-out);

			display: block;
			background-image: url(../images/faq-plus-icon.svg);
			background-repeat: no-repeat;
			background-position: center;
		}
	}


	span {

		&.active {
			color: map-get($map: $colors, $key: blue);
			&::after {
				background-image: url(../images/faq-minus-icon.svg);
			}
		}

	}
}

.faq-content {
	display: none;
	border-top: 0;
	color: map-get($map: $colors, $key: white50);
	margin-bottom: 40px;
	letter-spacing: -0.01em;
	font-size: 20px;
	padding-right: 80px;

	p {
		color: map-get($map: $colors, $key: white50);
		margin: 0;
	}
}
