/* -------------------------------------------------
>> Base Styles
---------------------------------------------------
>> Edit these styles as required
------------------------------------------------- */

body {

	@include font(gs, reg, family weight);
	position: relative;
	font-size: 20px;
	color: map-get($map: $colors, $key: white50);
	line-height: 1.5;
	background-color: map-get($map: $colors, $key: dgray);

	@include responsive("small") {
		font-size: 18px;
	}
}

p {
	margin: 0 0 30px;
}

::selection {
	color: map-get($map: $colors, $key: white);
	background-color: map-get($map: $colors, $key: blue);
}
