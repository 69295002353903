.showroom-block-ctn {
	position: relative;
}

.hero-videos {
	max-width: 1520px;
	margin: 0 auto;
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	height: 150px;
	top: 366px;
}

.hero-agent-video,
.hero-guest-video {
	width: 302px;
	line-height: 0.65;
	border-radius: 6px;
	position: absolute;

	video {
		margin-bottom: 0;
		border-radius: 6px;
	}
}

.hero-agent-video {
	right: 0;
}

.agent-video,
.guest-video {
	line-height: 0.65;
	width: 13%;
	border-radius: 6px;
	position: absolute;
	right: 16px;
	top: calc(50% - 140px);
	z-index: 2;
	opacity: 0;

	video {
		margin-bottom: 0;
		border-radius: 6px;
	}
}

.guest-video {
	&.guest-animation {
		animation: scale 1s;
		animation-fill-mode: forwards;
	}
}
.for-mobile {
	display: none;
}

.agent-video {
	top: calc(50% - 12px);

	&.agent-animation {
		animation: scale 1s;
		animation-delay: 1s;
		animation-fill-mode: forwards;
	}
}

.showroom-area {
	&.played {
		.showroom-image {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.showroom-image {
	position: relative;
	z-index: 1;
	transition: 0.4s ease-in-out;
	border-radius: 10px;
	img {
		border-radius: 10px;
	}
}

.showroom-video {
	position: absolute;
	top: 0;

	video {
		margin-bottom: 0;
		border-radius: 10px;
	}
}
