/* -------------------------------------------------
>> Styles for form Input fields
------------------------------------------------- */
@import "inputs";

/* -------------------------------------------------
>> Styles for buttons
------------------------------------------------- */
@import "buttons";

/* -------------------------------------------------
>> Default styles for CheckBoxes
------------------------------------------------- */
@import "checkboxes";

/* -------------------------------------------------
>> Default styles for Radio Buttons
------------------------------------------------- */
@import "radio";

/* -------------------------------------------------
>> Default styles for Meter Tag
------------------------------------------------- */
@import "meter";

/* -------------------------------------------------
>> Default styles for Progress Tag
------------------------------------------------- */
@import "progress-bar";

/* -------------------------------------------------
>> Default styles for Range Field
------------------------------------------------- */
@import "range";
