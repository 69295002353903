@media (min-width: 1024px) {

	.header-nav ul li:hover>ul,
	.menu-item-has-children ul.focused,
	.header-nav ul li a:focus-visible+ul {
		opacity: 1;
		visibility: visible;
		overflow: visible;
	}

	.header-nav ul ul {
		animation-delay: 0.6s;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;

		@include transition(all, 0.3s, ease-in-out);
	}
}

.header-nav {
	text-align: center;
	line-height: 1;

	ul {
		margin: 0;
		padding: 0;
		position: relative;

		li {
			margin: 0 45px 0 0;
			padding: 0;
			display: inline-block;
			position: relative;
			line-height: 1.3;
			font-size: 18px;
			letter-spacing: 0.3px;

			@include font(gs, med, weight);

			a,
			a:visited {
				padding: 22px 0 23px;
				display: inline-block;
				line-height: 1.3;
				border: 0;
				position: relative;
				color: map-get($map: $colors, $key: white);

				@include font(gs, med, weight);

				&::after {
					content: "";
					position: absolute;
					width: 100%;
					height: 3px;
					bottom: 5px;
					left: 0;
					background-color: map-get($map: $colors, $key: white);
					opacity: 0.8;
					transform: scaleX(0);
					transform-origin: bottom right;
					transition: transform 0.3s;
				}
			}

			&.menu-item-has-children>a {
				padding-right: 26px;
				background-position: top 27px right;
				background-repeat: no-repeat;
				background-image: url(../images/down-arrow.svg);

				&::after {
					width: calc(100% - 24px);
				}
			}

			&.current-menu-item,
			&.current-menu-parent {

				a,
				a:visited {
					&::after {
						transform-origin: bottom left;
						transform: scaleX(1);
					}
				}

			}

			&:last-child {
				margin-right: 38px;
			}

			&:hover {
				a {
					&::after {
						transform-origin: bottom left;
						transform: scaleX(1);
					}
				}
			}
		}

		ul {
			left: -30px;
			width: 376px;
			opacity: 0;
			visibility: hidden;
			padding: 30px 20px 30px 30px;
			box-sizing: border-box;
			border-radius: 10px;
			position: absolute;
			top: 74px;
			backdrop-filter: blur(30px);
			z-index: 9;
			overflow: hidden;
			background-color: map-get($map: $colors, $key: black);
			@include transition(all, 0.4s, ease-in-out);

			text-align: left;

			&::before {
				content: "";
				width: 100%;
				height: 5px;
				background-color: transparent;
				position: absolute;
				left: 0;
				top: -5px;
			}

			&::after {
				content: "";
				width: 100%;
				height: 100%;
				background-color: map-get($map: $colors, $key: black70);
				position: absolute;
				left: 0;
				top: 0;
				border-radius: 10px;
				z-index: 1;
			}

			li {
				display: block;
				margin: 0 0 20px;
				padding: 0;
				background-repeat: no-repeat;
				background-position: left center;
				z-index: 10;

				&.meeting {
					background-image: url(../images/video-icon.svg);
				}

				&.screencast {
					background-image: url(../images/screencast-icon.svg);
				}

				&.ai {
					background-image: url(../images/ai-icon.svg);
				}

				&.matterport {
					background-image: url(../images/matterport-icon.svg);
				}

				&:last-child {
					margin: 0;
				}

				a,
				a:visited {
					font-size: 18px;
					line-height: 1.2;
					letter-spacing: 0.72px;
					display: inline-block;
					padding: 10px 0 10px 60px;
					box-sizing: border-box;
					position: relative;
					color: map-get($map: $colors, $key: white80);

					@include transition(all, 0.3s, ease-in-out);
					@include font(gs, med);

					&::after {
						display: none;
					}
				}

				a:hover {
					color: map-get($map: $colors, $key: white);
				}

				&.current-menu-item>a,
				&.current-menu-parent>a,
				a:hover {
					border: 0;

				}
			}

			ul {
				top: -10px;
				left: 230px;

				&::before {
					display: none;
					content: "";
					width: 1px;
					height: 1px;
					position: absolute;
					top: 12px;
					left: -5px;
					margin: auto;
				}
			}
		}
	}
}
