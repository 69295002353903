@media only screen and (min-width: 1200px) and (max-width: 1345px) {
	// code will be starts from here
	.header-wrapper {
		width: 97%;
	}

	.header-nav ul li {
		margin: 0 25px 0 0;

		&:last-child {
			margin-right: 0;
		}
	}

	.header-logo .partner-logo {
		width: 90px;
	}

	.content-box {
		padding-right: 20px;
	}

	.video-box {
		&:nth-child(2) {
			width: 55%;
		}

		&:nth-child(3) {
			width: 43%;
		}
	}

	.screencast-animation {
		width: 50%;
	}

	.extensions-tiles-ctn {
		width: 1090px;
	}

	.tooltip-content {
		left: auto;
		right: -12px;

		&::after {
			left: auto;
			right: 12px;
		}
	}

	.tooltip-standard {
		left: auto;
		right: 15px;

		&::after {
			left: auto;
			right: 12px;
		}
	}

	.header-wrapper {
		width: 95%;
	}

	.header-nav ul li {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
		}
	}

	.midpage-cta-content .cta-buttons {
		a,
		a:visited {
			&.button {
				padding: 19px 50px;
			}
		}
	}
	.guest-video {
		top: calc(50% - 125px);
	}
}

@media only screen and (min-width: 1200px) {
	@keyframes scale {
		0% {
			transform: scale(1);
			opacity: 0;
		}

		50% {
			transform: scale(1.25);
			opacity: 1;
		}

		100% {
			transform: scale(1);
			opacity: 1;
		}
	}
}

@media only screen and (max-width: 1199px) {
	.solution-hero-video {
		margin-top: 64px;
	}

	.hero-videos {
		display: none;
	}

	.showroom-image {
		display: none;
	}

	.agent-video,
	.guest-video {
		opacity: 1;
		position: initial;
		width: 47%;
	}

	.showroom-video {
		position: initial;
	}

	.small-videos {
		display: flex;
		justify-content: space-between;
		max-width: 470px;
		align-items: center;
		margin: 0 auto 20px auto;
		width: 100%;
	}
}

@media only screen and (min-width: 1004px) and (max-width: 1199px) {
	.get-in-touch-ctn {
		.git-content {
			h1 {
				font-size: 64px;
			}
		}
	}

	.get-in-touch-ctn.two-columns {
		gap: 60px;

		.column {
			width: calc(50% - 30px);
		}
	}

	// code will be starts from here
	.video-box {
		max-width: 100%;

		&:nth-child(2) {
			width: 49%;
			max-width: 100%;
		}

		&:nth-child(3) {
			width: 49%;
			max-width: 100%;
		}
	}

	.video-meeting-block,
	.screencast-block,
	.ai-features-block {
		.section-head h2 {
			background-position: top 15px left;
		}
	}

	.ai-feature-content,
	.ai-feature-animation,
	.screencast-animation,
	.sc-content {
		width: 48%;
	}

	.extension-hero-content.d-flex {
		display: block;
	}

	.filters-button-group {
		margin-top: 30px;
	}

	.extensions-tiles-ctn {
		width: 100%;
		margin-left: -1.625%;
	}

	.extensions-tiles-ctn.three-columns .column {
		width: calc(50% - 3.25%);
	}

	.extensions-tiles-ctn .extension-box {
		margin: 0 1.625% 3.25% 1.625%;
	}

	.tooltip-content {
		left: auto;
		right: -12px;

		&::after {
			left: auto;
			right: 12px;
		}
	}

	.tooltip-standard {
		left: auto;
		right: 15px;

		&::after {
			left: auto;
			right: 12px;
		}
	}

	.videos-block-ctn .video-box .content-box {
		padding-right: 40px;
	}

	.videos-block-ctn .video-box:nth-child(2) .video-content,
	.videos-block-ctn .video-box:nth-child(3) .video-content {
		font-size: 22px;
	}

	.extension-box .extension-content {
		padding: 20px 20px 30px 22px;
	}

	.tab-video-thumb {
		width: 90px;
	}

	.video-title-area {
		width: calc(100% - 110px);
	}

	.icon-with-text-block.three-columns {
		gap: 40px 30px;
	}

	.icon-with-text-block.three-columns .column {
		width: calc(33.33% - 20px);
	}

	.midpage-cta-content {
		padding: 50px 0 50px 50px;

		a,
		a:visited {
			&.button {
				margin-right: 0;
				display: block;

				&.call-btn {
					display: inline-flex;
					margin-top: 15px;
					width: 100%;
				}
			}
		}
	}

	.midpage-cta-ctn.without-image {
		.midpage-cta-content {
			.cta-buttons {
				display: flex;
				align-items: center;
				justify-content: center;

				a,
				a:visited {
					&.button {
						margin-right: 15px;
						display: inline-block;

						&.call-btn {
							display: inline-flex;
							margin-top: 0;
							width: auto;
						}
					}
				}
			}
		}
	}

	.midpage-cta-content {
		.cta-buttons {
			display: block;
		}
	}
}

@media only screen and (max-width: 1003px) {
	.hero-icon {
		margin-bottom: 24px;
	}

	// code will be starts from here
	.client-detail.d-flex {
		align-items: center;
	}

	#main-section {
		.d-flex {
			display: block !important;
		}

		.videos-block-ctn,
		.client-detail,
		.tabs-area {
			&.d-flex {
				display: flex !important;
			}
		}

		.video-box {
			&:first-child {
				display: flex !important;

				.content-box {
					padding-right: 24px;
				}
			}
		}
	}

	.tabs-area {
		flex-direction: column-reverse;
	}

	.left-column {
		width: 100%;
		border-radius: 0 0 10px 10px;
	}

	.right-column {
		width: 100%;
	}

	.tabs-area .feature-video-area {
		img,
		video {
			border-radius: 10px 10px 0 0;
		}
	}

	.content-box {
		padding: 30px 18px 0 18px;
		max-width: 100%;
	}

	.videos-block-ctn {
		.video-content {
			font-size: 20px;
		}

		.video-box {
			padding: 30px;

			&:nth-child(2),
			&:nth-child(3) {
				.block-video {
					margin: 0 0 -30px -30px;
				}

				.video-content {
					margin-bottom: 40px;
				}
			}
		}
	}

	.extensions-tiles,
	.icon-tiles-area,
	.icon-with-text-block {
		&.three-columns {
			gap: 20px;

			.column {
				width: calc(50% - 10px);
			}
		}
	}

	.video-box {
		max-width: 100%;

		.animation-box {
			padding: 46px 18px 24px 18px;
			display: flex;
			justify-content: flex-end;
		}

		.lottie-box {
			max-width: 100%;
			width: 60%;
		}

		&:nth-child(2) {
			width: 100%;
			max-width: 100%;

			.animation-box {
				justify-content: flex-start;
				padding: 46px 18px 0 0;

				.lottie-box {
					max-width: 100%;
					width: 80%;
				}
			}
		}

		&:nth-child(3) {
			width: 100%;
			max-width: 100%;

			.animation-box {
				justify-content: flex-end;
				padding: 46px 0 24px 18px;

				.lottie-box {
					max-width: 100%;
					width: 80%;
				}
			}
		}
	}

	.testimonials-block {
		width: 105.5%;
	}

	.testimonials-area {
		.owl-stage {
			padding-left: 0 !important;
		}
	}

	.testimonials-area {
		height: auto;
		-webkit-mask-image: initial;
	}

	.video-meeting-block,
	.screencast-block,
	.ai-features-block {
		.section-head h2 {
			padding: 84px 0 0 0;
			background-size: 56px;
			background-position: left top;
		}
	}

	.list-block {
		&.three-columns {
			display: block;
			max-width: 500px;
			margin: 0 auto;

			.column {
				width: 100%;
				margin-bottom: 30px;
				position: relative;

				&:last-child {
					margin-bottom: 0;

					.list-number::after {
						display: none;
					}
				}
			}
		}
	}

	.list-number {
		position: absolute;
		height: 100%;

		span {
			width: 48px;
			height: 48px;
			font-size: 26px;
			margin-bottom: 0;
		}

		&::after {
			height: calc(100% - 79px);
			position: absolute;
			top: auto;
			width: 1px;
			right: auto;
			bottom: 0;
			left: 25px;
		}
	}

	.list-block-content {
		padding: 0 0 24px 68px;

		p {
			max-width: 100%;
		}
	}

	.iat-block-ctn.with-box {
		padding: 40px;
	}

	.iat-block.two-columns {
		display: block;

		.column {
			width: 100%;
		}

		.iat-content-area {
			padding-top: 0;
		}

		.iat-imaga-area {
			margin-top: 64px;

			img {
				aspect-ratio: initial;
			}
		}
	}

	.midpage-cta-image {
		width: 100%;
		border-radius: 0 0 10px 10px;
		padding-top: 56.25%;
	}

	.midpage-cta-content {
		padding: 50px;
		width: 100%;
		text-align: center;
	}
}

@media only screen and (min-width: 748px) and (max-width: 1003px) {
	// code will be starts from here
	.video-meeting-block,
	.screencast-block,
	.ai-features-block {
		.section-head h2 {
			padding: 100px 0 0 0;
			background-size: 72px;
		}
	}

	.screencast-animation,
	.ai-feature-animation {
		margin-top: 64px;
	}

	.video-detail-ctn,
	.about-client {
		&.d-flex {
			display: flex;
		}
	}

	.screencast-animation,
	.sc-content {
		width: 48%;
	}

	.filters-button-group {
		margin-top: 30px;
	}

	.extensions-tiles-ctn {
		width: 100%;
		margin-left: -1.625%;
	}

	.extensions-tiles-ctn.three-columns .column {
		width: calc(50% - 3.25%);
	}

	.extensions-tiles-ctn .extension-box {
		margin: 0 1.625% 3.25% 1.625%;
	}

	/*****Pricing page******/

	.pricing-hero-ctn {
		margin-bottom: 30px;
	}

	#main-section .pricing-hero-content.d-flex {
		display: flex !important;
	}

	.enterprise-content {
		width: 100%;
	}

	.enterprise-button {
		width: 100%;
		margin-top: 30px;
	}

	.tooltip-content {
		left: 0;
		right: 0;
		margin: 0 auto;

		&::after {
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}

	.tooltip-standard {
		left: auto;
		right: 16px;

		&::after {
			left: auto;
			right: 12px;
		}
		&.single-line-text {
			&::after {
				left: initial;
				right: initial;
			}
		}
	}

	.get-in-touch-ctn {
		&.two-columns .column {
			width: 100%;
		}

		.git-content {
			margin-bottom: 80px;
		}
	}

	.midpage-cta-content .cta-buttons {
		display: block;

		a,
		a:visited {
			&.button {
				margin: 0 auto 15px auto;
				width: 320px;
				display: block;

				&.call-btn {
					margin: 0 auto;
					display: inline-flex;
				}
			}
		}
	}
}

@media only screen and (max-width: 747px) {
	.hero-meeting-form-ctn {
		background-color: transparent;
		margin-top: 36px;
		margin-bottom: 24px;
		form {
			flex-wrap: wrap;
			input[type="text"] {
				width: 100%;
				margin: 0 0 16px 0;
				height: 54px;
				border-radius: 10px;
			}
			input[type="submit"] {
				border-radius: 8px;
				width: 100%;
				height: 54px;
			}
		}
	}
	.hero-services-ctn {
		flex-wrap: wrap;
		justify-content: center;
		gap: 24px;
	}
	.hero-services-heading {
		text-align: center;
	}
	.hero-services-main {
		width: 100%;
		gap: 4px;
		a,
		a:visited {
			padding: 2px;
			width: 100px;

			span.hero-service-icon {
				width: 30px;
				height: 30px;
				bottom: -14px;
			}
		}
	}
	.hero-text p {
		font-size: 20px;
	}
	.tooltip-standard {
		left: auto;
		right: 0px;

		&::after {
			left: auto;
			right: 24px;
		}
		&.single-line-text {
			left: auto;
			right: -24px;
		}
	}

	.extension-popup-ctn {
		padding: 5px 5px 36px 5px;
	}

	.extension-popup-video video {
		margin-bottom: 24px;
	}

	.extension-popup-content .text-32 {
		margin-bottom: 20px;
	}

	.extension-popup-content ul li {
		margin-bottom: 18px;
	}

	.extension-popup-content p {
		margin-bottom: 18px;

		&:last-of-type {
			margin-bottom: 30px;
		}
	}

	.hero-icon {
		width: 56px;
		height: 56px;
		margin-bottom: 24px;
	}

	.solution-hero-content p {
		font-size: 20px;
		margin-bottom: 36px;
	}

	.solution-hero-content a.down-arrow-btn,
	.solution-hero-content a:visited.down-arrow-btn {
		padding: 15px 45px 16px 20px;
		background-position: right 20px center;
		background-size: 12px;
	}

	.play-button {
		a,
		a:visited {
			height: 64px;
			width: 64px;

			img {
				width: 18px !important;
				height: 18px;
				border-radius: 0 !important;
			}
		}
	}

	a.call-btn span,
	a:visited.call-btn span {
		margin-right: 12px;
		width: 24px;
		height: 24px;
	}

	.list-block.three-columns {
		max-width: 100%;
	}

	.list-block-content {
		padding: 0 10px 24px 68px;

		p {
			font-size: 20px;
		}
	}

	.list-block-heading {
		padding-top: 7px;
	}

	.iat-block-ctn.with-box {
		padding: 24px;
	}

	.iat-content-area p {
		font-size: 20px;
	}

	.iat-content-area ul li {
		font-size: 20px;
		padding-left: 40px;
		background-size: 26px;
	}

	.iat-block.two-columns .iat-imaga-area {
		margin-top: 46px;
	}

	.iat-block.two-columns .iat-imaga-area img {
		aspect-ratio: initial;
	}

	.icon-tiles-ctn .section-head h2 {
		margin-bottom: 46px;
	}

	.icon-tile {
		padding: 30px 24px;
	}

	.icon-tile .icon {
		margin-bottom: 18px;

		img {
			max-width: 58px;
			max-height: 58px;
		}
	}

	.icon-tile p {
		font-size: 16px;
	}

	.extensions-tiles,
	.icon-tiles-area,
	.icon-with-text-block {
		&.three-columns {
			gap: 24px 0;
			display: flex;

			.column {
				width: 100%;
			}
		}
	}

	.midpage-cta-content {
		padding: 24px 24px 46px 24px;
		text-align: left;

		.cta-buttons {
			display: block;

			a,
			a:visited {
				&.button {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					margin-right: 0;
					width: 100%;

					&.call-btn {
						padding: 12px 20px 12px 20px;
						margin-top: 18px;
					}
				}
			}
		}
	}

	.midpage-cta-content p {
		margin-bottom: 36px;
		font-size: 22px;
	}

	.midpage-cta-image {
		padding-top: 89%;
	}

	#main-section .video-box:first-child {
		display: block !important;

		.content-box {
			padding-right: 0;
		}
	}

	.videos-block-ctn {
		.video-box {
			padding: 30px 18px 24px 18px;

			&:nth-child(2),
			&:nth-child(3) {
				width: 100%;

				.block-video {
					margin: 0 0 -24px -18px;

					img {
						aspect-ratio: 1/0.62;
					}
				}
			}
		}

		.video-content {
			font-size: 22px;
		}

		.content-box {
			margin-bottom: 46px;
		}
	}

	.image-with-discription-ctn {
		padding: 36px 24px 24px 24px;
	}

	.image-with-discription-ctn,
	.extension-block {
		.section-head {
			h2 {
				margin-bottom: 40px;
			}

			p {
				font-size: 22px;
			}
		}
	}

	.faq-block {
		.section-head {
			h2 {
				margin-bottom: 0;
			}
		}
	}

	.faq > span {
		padding: 24px 0;
		font-size: 20px;
		padding-right: 58px;

		&::after {
			width: 24px;
			height: 24px;
			top: 26px;
		}
	}

	.faq-content {
		padding-right: 0;
		font-size: 16px;
		margin-bottom: 24px;
	}

	.quote-block-heading {
		background-size: 40px;
		padding: 64px 0 0 0;
	}

	.quote-block-heading h2 {
		margin-bottom: 30px;
	}

	.quote-content p {
		font-size: 26px;
		margin-bottom: 30px;
	}

	.quote-client-image img {
		max-width: 72px;
		max-height: 72px;
	}

	.quote-client-image {
		margin-bottom: 18px;
	}

	.video-tabs-ctn {
		.section-head {
			h2 {
				margin-bottom: 6px;
			}
		}
	}

	.tabs-area .feature-video-area img,
	.tabs-area .feature-video-area video {
		aspect-ratio: 1/0.75;
	}

	.left-column {
		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
		}
	}

	.tab-link-ctn a,
	.tab-link-ctn a:visited {
		padding: 12px;
	}

	.left-column {
		height: 320px;
	}

	.tab-video-thumb {
		width: 95px;
	}

	.video-title-area {
		width: calc(100% - 111px);
	}

	.video-title-area .text-18 {
		margin-bottom: 9px;
	}

	.box-icon img {
		max-width: 58px;
		max-height: 58px;
	}

	.icon-box-content {
		width: calc(100% - 76px);
	}

	.icon-with-text-block.three-columns {
		gap: 36px 0;
	}

	.midpage-cta-ctn {
		.midpage-cta-content {
			h2 {
				max-width: 90%;
				margin-bottom: 24px;
			}
		}

		&.without-image {
			.midpage-cta-content {
				width: 100%;
				padding: 30px 24px 40px 24px;

				h2 {
					margin: 0 auto 24px auto;
				}
			}
		}
	}

	/*****Get in touch page******/
	.contact-links {
		margin-bottom: 40px;
	}

	.git-content {
		margin-bottom: 64px;

		h1 {
			margin-bottom: 24px;
		}

		p {
			font-size: 20px;
			margin-bottom: 30px;
		}
	}

	.contact-links ul li {
		margin-bottom: 24px;

		a,
		a:visited {
			padding: 0 0 0 40px;
			background-size: 26px;
		}
	}

	.contact-links span.contact-address {
		padding: 0 0 0 40px;
		background-size: 26px;
	}

	.contact-links p {
		font-size: 16px;
		max-width: 250px;
	}

	.demo-call {
		a,
		:visited {
			width: 100%;

			&.call-btn {
				padding: 13px 20px 14px 20px;
			}
		}
	}

	.git-form {
		padding: 24px;
	}

	/*****Pricing page******/

	.two-columns,
	.three-columns,
	.four-columns {
		display: block;
	}

	.switch-wrapper {
		display: inline-block;
		margin-top: 30px;

		label {
			padding: 6px 14px 7px 14px !important;
			font-size: 15px;
		}
	}

	.pricing-block-ctn .three-columns .column {
		width: 100%;
		margin-top: 24px;

		&:first-child {
			margin-top: 0;
		}
	}

	.pricing-column.recommended {
		border-radius: 10px;
	}

	.recommended-heading {
		position: initial;
	}

	.price-head {
		padding: 18px 40px;
		font-size: 18px;
	}

	.orignal-price {
		font-size: 26px;
	}

	.discounted-price {
		font-size: 48px;
	}

	.table-button {
		a,
		a:visited {
			&.button {
				padding: 11px 16px 12px 16px;
				font-size: 16px;
			}
		}
	}

	.table-text {
		padding: 18px;
		font-size: 16px;
	}

	.tooltip-content {
		left: auto;
		right: -13px;

		&::after {
			right: 12px;
			left: auto;
		}
	}

	#main-section .table-text.d-flex {
		display: flex !important;
	}

	.price-notice-ctn {
		padding: 18px;
	}

	.notice-heading {
		font-size: 15px;
		margin-bottom: 12px;
	}

	.price-notice-ctn ul li {
		font-size: 15px;
		margin-bottom: 6px;
	}

	.enterprise-content-area {
		padding: 24px 29px 36px 24px;
	}

	.enterprise-content {
		width: 100%;
	}

	.enterprise-content .heading-3 {
		margin-bottom: 24px;
	}

	.enterprise-button {
		width: 100%;
		max-width: 400px;
		margin: 30px auto 0 auto;

		a,
		a:visited {
			display: block;
		}
	}

	.cta-heading br {
		display: block;
	}

	.cta-heading span {
		margin: 0 8px 0 0;
		top: 2px;

		img {
			width: 31px;
		}
	}

	.agent-video,
	.guest-video,
	.showroom-video {
		video {
			border-radius: 4px;
		}
	}

	a,
	a:visited {
		&.button {
			padding: 15px 40px 16px 40px;
			font-size: 18px;
		}
	}

	.hero-home {
		width: 90%;

		h1 {
			margin-bottom: 24px;
		}
	}

	.hero-button {
		max-width: 500px;
		margin: 46px auto 0 auto;

		a,
		a:visited {
			&.button {
				display: block;
			}
		}
	}

	.hero-jumplinks {
		margin-top: 36px;

		a,
		a:visited {
			&.jump-link {
				display: block;
				text-align: center;
				margin-right: 13px;
				padding: 0;

				.jumplink-img {
					display: block;
					margin-bottom: 18px;
				}

				.jumplink-text {
					margin-left: 0;
					font-size: 16px;
					line-height: 1.2;
					display: block;
				}

				&:hover {
					border: 1px solid transparent;
					background-color: transparent;
				}
			}
		}
	}

	.scrolling-logo {
		width: 54px;
	}

	.plus-icon {
		margin: 0 10px;
		width: 16px;
	}

	.video-title {
		margin-bottom: 18px;
	}

	.sc-feature {
		margin-bottom: 36px;
	}

	.sc-feature-content {
		span {
			margin-bottom: 18px;
			font-size: 22px;
		}

		p {
			font-size: 22px;
		}
	}

	.sc-head p {
		margin-bottom: 40px;
		font-size: 22px;
	}

	.sc-icon span {
		width: 56px;
		height: 56px;

		img {
			max-width: 30px;
			max-height: 30px;
		}
	}

	.video-box {
		.lottie-box {
			width: 100%;
		}

		&:nth-child(2) {
			width: 100%;

			.animation-box {
				.lottie-box {
					width: 100%;
				}
			}
		}

		&:nth-child(3) {
			.animation-box {
				.lottie-box {
					width: 100%;
				}
			}
		}
	}

	.screencast-animation {
		margin-top: 64px;
	}

	.logos-ctn {
		margin-top: 16px;

		.owl-stage-outer {
			padding: 30px 0;
		}
	}

	.logos-slider {
		-webkit-mask-image: initial;
	}

	.logo-slide {
		width: 72px;
		height: 72px;

		span {
			padding: 16px;

			img {
				max-width: 38px;
				height: 38px;
			}
		}
	}

	.block-button {
		max-width: 500px;
		margin: 46px auto 0 auto;

		a,
		a:visited {
			display: block;
		}
	}

	.typing-text p {
		font-size: 26px;
	}

	.extension-box {
		width: 277px;
		margin-right: 10px;

		&.extension-content {
			padding: 18px 18px 30px 18px;
		}

		a,
		a:visited {
			margin-top: 24px;
			padding: 6px 0 5px 44px;
			background-size: 30px;
			font-size: 16px;
		}

		h3 {
			margin-bottom: 18px;
		}

		p {
			font-size: 16px;
		}
	}

	.extension-tag {
		border-radius: 5px;
		padding: 3px 8px 2px;
		font-size: 14px;
	}

	.ai-feature-animation {
		line-height: 0.65;
		margin-top: 64px;
	}

	.testi-content p {
		font-size: 16px;
	}

	.client-designation {
		span {
			font-size: 16px;
			margin-bottom: 4px;
		}

		p {
			font-size: 16px;
		}
	}

	.social-icons.d-flex {
		display: flex;
	}

	.social-icons {
		a,
		a:visited {
			border-radius: 2px;
		}
	}

	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="tel"],
	input[type="url"],
	input[type="search"],
	input[type="password"],
	input[type="time"],
	input[type="date"],
	input[type="datetime-local"],
	input[type="week"],
	input[type="month"],
	input[type="file"],
	input[type="range"],
	input[list],
	input[type="string"],
	select,
	textarea,
	.gform-text-input-reset {
		height: 44px;
		font-size: 15px;
	}

	input[type="submit"],
	button,
	input[type="button"],
	input[type="reset"] {
		height: 54px;
		font-size: 18px;
	}

	.simple-hero {
		padding: 27px 0 46px;
	}

	.text-block h1,
	.text-block h2,
	.text-block h3,
	.text-block h4,
	.text-block h5,
	.text-block h6 {
		margin-bottom: 24px;
	}

	.page-section {
		ul,
		ol {
			margin: 24px 0 0 20px;
		}

		.tooltip-content ul {
			margin: 0 0 12px 0;
		}

		.price-notice-ctn ul {
			margin: 0 0 0 3px;
		}

		.contact-links,
		.iat-content-area,
		.extension-popup-content {
			ul {
				margin: 0;
			}
		}
	}

	.text-24 {
		p {
			font-size: 22px;
		}
	}

	p {
		font-size: 18px;
	}

	// columns
	.two-columns,
	.three-columns,
	.four-columns,
	.five-columns {
		.column {
			width: 100%;
		}
	}

	// at block
	.iat-image.column {
		margin-top: 30px;
	}

	// lists
	ul.children,
	ol.children {
		li {
			&.depth-2,
			&.depth-3,
			&.depth-4,
			&.depth-5 {
				margin: 0 0 30px 20px !important;
			}
		}
	}

	// forms styling
	.gfield.half-input-field,
	.ginput_left,
	.name_first,
	.ginput_right,
	.name_last {
		width: 100%;
		margin-right: 0;
		display: block;
	}

	// Wp Table Block
	figure {
		overflow-x: scroll;

		td,
		th {
			width: 140px;
		}
	}
}

@media only screen and (min-width: 460px) and (max-width: 747px) {
	// code will be starts from here
}

@media only screen and (max-width: 459px) {
	// code will be starts from here
}
