/* -------------------------------------------------
>> Accessibility styles for WordPress Gallery Block
------------------------------------------------- */
@import "gallery";

/* -------------------------------------------------
>> Accessibility styles for WordPress Cover Block
------------------------------------------------- */
@import "cover";

/* -------------------------------------------------
>> Accessibility styles for WordPress Button Block
------------------------------------------------- */
@import "button";

/* -------------------------------------------------
>> Accessibility styles for WordPress Calender Block
------------------------------------------------- */
@import "calendar";

/* -------------------------------------------------
>> Accessibility styles for WordPress Categories Block
------------------------------------------------- */
@import "categories";

/* -------------------------------------------------
>> Accessibility styles for WordPress Image Block
------------------------------------------------- */
@import "image";

/* -------------------------------------------------
>> Accessibility styles for WordPress Latest Comments Block
------------------------------------------------- */
@import "latest-comments";

/* -------------------------------------------------
>> Accessibility styles for WordPress Latest Posts Block
------------------------------------------------- */
@import "latest-posts";

/* -------------------------------------------------
>> Accessibility styles for WordPress Quote Block
------------------------------------------------- */
@import "quote";

/* -------------------------------------------------
>> Accessibility styles for WordPress PullQuote Block
------------------------------------------------- */
@import "pullquote";

/* -------------------------------------------------
>> Accessibility styles for WordPress RSS Feed Block
------------------------------------------------- */
@import "rss-feed";

/* -------------------------------------------------
>> Accessibility styles for WordPress Search Block
------------------------------------------------- */
@import "search";

/* -------------------------------------------------
>> Accessibility styles for WordPress Social-links Block
------------------------------------------------- */
@import "social-links";

/* -------------------------------------------------
>> Accessibility styles for WordPress Tag Cloud Block
------------------------------------------------- */
@import "tag-cloud";
