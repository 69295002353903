
.clear {
	content: "";
	display: table;
	table-layout: fixed;
	clear: both;

	&::before,
	&::after {
		clear: both;
	}
}
