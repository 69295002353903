.two-columns,
.three-columns,
.four-columns {
	display: flex;
	flex-wrap: wrap;
}

.two-columns {
	gap: 120px;
	.column {

		width: calc(50% - 60px)
	}
}

.three-columns {
	gap: 20px 40px;

	.column {
		width: calc(33.33% - 26.66px)
	}
}

.four-columns {

	.column {

		@include column(4, 3%);
	}
}

.five-columns {

	.column {

		@include column(5, 3%);
	}
}
