.icon-tiles-ctn {
	.section-head {
		h2 {
			margin-bottom: 80px;
		}
	}
}

.icon-tiles-area {
	&.three-columns {
		gap: 30px 30px;
	}

	.column {
		width: calc(33.33% - 20px);
	}
}

.icon-tile {
	padding: 40px 23px 40px 30px;
	border-radius: 10px;
	background-color: map-get($map: $colors, $key: yellow);
	box-sizing: border-box;
	.icon {
		line-height: 0.65;
		margin-bottom: 20px;
		img {
			max-width: 72px;
			max-height: 72px;
		}
	}
	h3 {
		margin-bottom: 20px;
	}
	p {
		font-size: 18px;
		@include font(gs, med, weight);
		color: map-get($map: $colors, $key: white80);

		&:last-child {
			margin-bottom: 0px;
		}
	}
	&:nth-child(2), &:nth-child(8) {
		background-color: map-get($map: $colors, $key: green);
	}
	&:nth-child(3), &:nth-child(9) {
		background-color: map-get($map: $colors, $key: purple);
	}
	&:nth-child(4), &:nth-child(10) {
		background-color: map-get($map: $colors, $key: purple);
	}
	&:nth-child(5), &:nth-child(11) {
		background-color: map-get($map: $colors, $key: yellow);
	}
	&:nth-child(6), &:nth-child(12) {
		background-color: map-get($map: $colors, $key: green);
	}

}
