/* -------------------------------------------------
>> Project Styles
---------------------------------------------------
>> Edit these styles as required
------------------------------------------------- */

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + span,
[type="checkbox"]:not(:checked) + span {
	position: relative;
	padding-left: 26px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: map-get( $map: $base_colors, $key: checkbox_color );
	box-sizing: border-box;
	margin: 0 20px 10px 0;
}

[type="checkbox"]:checked + label::before,
[type="checkbox"]:not(:checked) + label::before,
[type="checkbox"]:checked + span::before,
[type="checkbox"]:not(:checked) + span::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid map-get( $map: $base_colors, $key: checkbox_border_color );
	background-color: map-get( $map: $base_colors, $key: checkbox_bgcolor );
}

[type="checkbox"]:checked + label::after,
[type="checkbox"]:not(:checked) + label::after,
[type="checkbox"]:checked + span::after,
[type="checkbox"]:not(:checked) + span::after {
	content: "";
	width: 10px;
	height: 10px;
	background-color: map-get( $map: $base_colors, $key: checkbox_after_bgcolor );
	position: absolute;
	top: 4px;
	left: 4px;
	transition: all 0.2s ease;
}

[type="checkbox"]:not(:checked) + label::after,
[type="checkbox"]:not(:checked) + span::after {
	opacity: 0;
	transform: scale( 0 );
}

[type="checkbox"]:checked + label::after,
[type="checkbox"]:checked + span::after {
	opacity: 1;
	transform: scale( 1 );
}
