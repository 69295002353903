.midpage-cta-ctn {
	background-color: map-get($map: $colors, $key: blue);
	border-radius: 10px;

	&.without-image {
		.midpage-cta-content {
			width: 90%;
			padding: 60px 0 75px;
			max-width: 980px;
			text-align: center;
			margin: 0 auto;
		}
		.cta-buttons {
			justify-content: center;
		}
	}
}

.midpage-cta-block {
	gap: 80px;
}

.midpage-cta-content {
	padding: 60px 0 75px 60px;
	box-sizing: border-box;
	width: calc(100% - 460px);

	h3 {
		margin-bottom: 35px;
	}

	p {
		margin-bottom: 50px;
		line-height: 1.4;
		font-size: 24px;
		color: map-get($map: $colors, $key: white80);
		@include font(gs, med, weight);
	}

	.cta-buttons {
		display: flex;
		align-items: flex-start;
		gap: 30px;

		a,
		a:visited {
			&.button {
				background-color: map-get($map: $colors, $key: dgray);
				margin-right: 0;

				&:hover {
					background-color: map-get($map: $colors, $key: black);
				}

				&.call-btn {
					background-color: map-get($map: $colors, $key: blue);
					border: 1px solid map-get($map: $colors, $key: white);
					box-sizing: border-box;
					margin-left: 0;
					padding: 17px 29px 17px 29px;


					&:hover {
						background-color: map-get($map: $colors, $key: white10);
					}
				}
			}
		}
	}
}

.midpage-cta-image {
	line-height: 0.65;
	width: 380px;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 0 10px 10px 0;
}
