/* -------------------------------------------------
>> Base Styles
---------------------------------------------------
>> Edit these styles as required
------------------------------------------------- */

table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0 0 30px;
	width: 100%;
	table-layout: fixed;
	text-align: left;
	box-sizing: border-box;

	p {

		&:last-child {
			margin-bottom: 0;
		}
	}

	caption {
		color: map-get($map: $base_colors, $key: caption_color);
		font-size: 90%;
		margin: 0 0 20px;
	}
}


tr,
tbody tr {
	border-bottom: 1px solid map-get($map: $base_colors, $key: table_border_color);

	&:last-child {
		border-bottom: none;
	}
}

td,
th {
	padding: 10px 15px;
	vertical-align: middle;
}

thead {

	tr {
		border: none;
	}

	tr:last-child {
		border-bottom: 3px solid map-get($map: $base_colors, $key: table_head_border_color);
	}

	th {
		font-weight: 700;
	}
}

tfoot {

	tr {
		border: none;
	}

	tr:first-child {
		border-top: 3px solid map-get($map: $base_colors, $key: table_foot_border_color);
	}

	td {
		font-weight: 700;
	}
}


.wp-block-table {

	tbody td {
		border: 2px solid map-get($map: $base_colors, $key: table_border_color);
	}

	&.is-style-stripes {

		tbody {


			tr,
			td {
				border: none;
			}
		}

		tfoot {

			tr {
				border-top: 3px solid map-get($map: $base_colors, $key: table_head_border_color);
			}
		}

		.has-background tbody tr:nth-child(odd) {
			background-color: transparent;
		}
	}

	table.has-background {

		tbody td,
		tbody tr {
			border: none;
		}

		tfoot tr {
			border-top: 3px solid map-get($map: $base_colors, $key: table_head_border_color);
		}
	}
}
