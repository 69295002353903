/* -------------------------------------------------
>> Base Styles
---------------------------------------------------
>> Edit these styles as required
------------------------------------------------- */

.screen-reader-text {
	margin: -1px;
	overflow: hidden;
	width: 1px;
	position: absolute !important;
	word-wrap: normal !important;
	color: map-get($map: $base_colors, $key: acc_btn_color);
	background-color: map-get($map: $base_colors, $key: acc_btn_bgcolor);
	clip: auto !important;
	border: unset !important;
	border-radius: 0;
	left: -100px;
	font-size: 14px;

	clip-path: none;
	font-weight: 700;
	height: auto;
	line-height: normal;
	padding: 8px 12px;
	text-decoration: none;
	top: 8px;

	&:hover,
	&:active,
	&:focus {
		display: block;
		width: auto;
		z-index: 100000;
		left: 8px;

	}
}

#page-section[tabindex="-1"]:focus {
	outline: 0;
}


a,
button,
input[type="submit"] {
	outline: 2px dotted transparent;
	outline-offset: 5px;

	&:focus-visible {
		outline-color: map-get($map: $colors, $key: pure_black) !important;
	}
}

.contact-details {

	a {
		outline-offset: 0;
	}
}


.header-logo,
.red-ctn,
.black-ctn,
.hero-ctn,
.topbar,
.header-nav ul li {

	a:focus-visible {
		outline-color: map-get($map: $colors, $key: pure_black);
	}
}
