.get-in-touch-ctn {
	justify-content: space-between;
	align-items: flex-start;
}

.git-content {
	max-width: 580px;

	h1 {
		margin-bottom: 30px;
	}

	p {
		color: map-get($map: $colors, $key: white);
		margin-bottom: 40px;
		font-size: 22px;
		@include font(gs, med, weight);
	}
}

.contact-links {
	margin-bottom: 60px;

	ul {
		margin: 0;
		padding: 0;

		li {
			display: block;
			padding: 0;
			margin: 0 0 30px 0;


			&::before {
				display: none;
			}

			a,
			a:visited {
				display: inline-block;
				text-decoration: underline;
				text-decoration-color: map-get($map: $colors, $key: white50);
				text-decoration-thickness: 1px;
				text-underline-offset: 4px;
				@include font(gs, sbold, weight);
				color: map-get($map: $colors, $key: white);
				border-bottom: 0;
				background-repeat: no-repeat;
				background-position: left center;
				padding: 0 0 0 44px;

				&:hover {
					color: map-get($map: $colors, $key: white);
					text-decoration-color: transparent;
				}

				&.contact-email {
					background-image: url(../images/email-icon.svg);
				}

				&.contact-fb {
					background-image: url(../images/contact-facebook.svg);
				}
			}
		}
	}

	span.contact-address {
		color: map-get($map: $colors, $key: white);
		display: block;
		@include font(gs, sbold, weight);
		background-repeat: no-repeat;
		background-position: left center;
		padding: 0 0 0 44px;
		margin-bottom: 20px;
		background-image: url(../images/location-icon.svg);
	}

	p {
		max-width: 310px;
		color: map-get($map: $colors, $key: white50);
		margin-bottom: 0;
		line-height: 1.5;
		font-size: 18px;
	}
}

.git-form {
	padding: 30px;
	border-radius: 10px;
	background-color: map-get($map: $colors, $key: white);
	box-sizing: border-box;
	max-width: 480px;

	form {
		margin-bottom: 0;
	}

	input[type="submit"] {
		margin-bottom: 0;
		background-color: map-get($map: $colors, $key: dgray);
		@include font (gs, med);

		&:hover {
			background-color: map-get($map: $colors, $key: black);
		}
	}

	textarea {
		height: 200px;
	}
}

a,
a:visited {
	&.call-btn {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 19px 30px 19px 30px;

		span {
			display: inline-block;
			line-height: 0.65;
			margin-right: 16px;
		}
	}
}

.form-heading {
	color: map-get($map: $colors, $key: dgray);
	line-height: 1.4;
	@include font (gs, med, weight);
	margin-bottom: 20px;
}


.thankyou-box-ctn {
	max-width: 480px;
	width: 100%;
	box-sizing: border-box;
	text-align: center;
	padding: 30px 30px 0 30px;
	background-color: #E7FFF3;
	border-radius: 10px;

	@include responsive('small') {
		padding: 24px 24px 0 24px;
		max-width: 100%;
	}

	.thankyou-heading {
		padding-top: 52px;
		background-image: url(../images/thank-you-icon.svg);
		background-position: top center;
		background-repeat: no-repeat;
		line-height: 1.3;
		margin-bottom: 20px;
		@include font(gs, med, weight);
		color: map-get($map: $colors, $key: dgray);

		@include responsive('small') {
			font-size: 26px;
			margin-bottom: 18px;
			background-size: 32px;
			padding-top: 46px;
		}
	}
}

.thankyou-content {
	p {
		color: map-get($map: $colors, $key: tblack);
		margin: 0 auto;
		max-width: 320px;
		font-size: 18px;
		@include font(gs, med, weight);

		@include responsive('small') {
			font-size: 16px;
			max-width: 248px;
		}
	}
}
