/* -------------------------------------------------
>> Base Styles
---------------------------------------------------
>> Do not edit these styles
------------------------------------------------- */

.wp-block-archives,
.wp-block-categories,
.wp-block-latest-comments,
.wp-block-latest-posts,
.wp-block-page-list,
.wp-block-rss,
.wp-block-search,
.wp-block-social-links,
.wp-block-tag-cloud,
.wp-block-calendar {
	margin: 0 0 40px;
}

/* -------------------------------------------------
>> Project Styles
---------------------------------------------------
>> Edit following styles or write your own
------------------------------------------------- */
