/* -------------------------------------------------
>> Project Styles
---------------------------------------------------
>> Edit these styles as required
------------------------------------------------- */

.hs-form {
	text-align: left;

	fieldset {
		margin: 0;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			margin: 0;
			padding-left: 0;

			label {
				margin: 0;
			}

			&::before {
				display: none;
			}
		}
	}

	ul.hs-error-msgs {

		li {
			padding-left: 0;
			color: map-get($map: $base_colors, $key: hubspot_error_label_color);
			font-size: 16px;
			margin: -18px 0 15px;
		}
	}

	.error {
		text-align: left;
	}

	.hs-fieldtype-checkbox,
	.hs-fieldtype-booleancheckbox,
	.hs-fieldtype-radio {

		ul.hs-error-msgs {

			li {
				margin: -6px 0 8px;
			}
		}
	}

	.hs_error_rollup {

		ul {

			li {
				background-color: map-get($map: $base_colors, $key: valiation_error_bg_color);
				margin: 10px 0 30px;
				padding: 7px 14px;
				font-weight: 700;
				font-size: 105%;
			}
		}
	}

	.hs-form-required {
		color: map-get($map: $base_colors, $key: hubspot_required_color);
	}
}

.hs-form-field {
	position: relative;
}

.form-columns-2 {

	.hs-form-field {
		display: inline-block;
		width: 49%;
		margin-right: calc(2% - 5px);

		&:nth-child(2n + 2) {
			margin-right: 0;
		}
	}
}
