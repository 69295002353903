.enterprise-content-area {
	background-color: map-get($map: $colors, $key: white);
	padding: 50px 64px 50px 50px;
	border-radius: 10px;
	justify-content: space-between;
	align-items: center;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: map-get($map: $colors, $key: dgray);
	}
}

.enterprise-content {
	width: calc(100% - 270px);

	.heading-3 {
		margin-bottom: 30px;
	}

	p {
		font-size: 18px;

		&:last-child {
			margin-bottom: 0px;
		}
	}
}

.enterprise-button {
	line-height: 0.65;
}
