/* -------------------------------------------------
>> Base Styles
---------------------------------------------------
>> Edit these styles as required
------------------------------------------------- */

h1,
.heading-1,
h2,
.heading-2,
h3,
.heading-3,
h4,
.heading-4,
h5,
.heading-5,
h6,
.heading-6 {

	@include font(gs, med);

	margin: 0 0 20px;
	color: map-get($map: $colors, $key: white);
	line-height: 1.05;
	text-transform: initial;

	a {
		color: map-get($map: $base_colors, $key: heading_link_color);
		border-bottom: none;

		&:hover {
			color: map-get($map: $base_colors, $key: heading_link_color_hover);
		}
	}
}


h1,
.heading-1 {
	font-size: 80px;

	@include responsive("small") {
		font-size: 48px;
	}

	@include responsive("tab") {
		font-size: 64px;
	}
}

h2,
.heading-2 {
	font-size: 64px;

	@include responsive("small") {
		font-size: 40px;
	}

	@include responsive("tab") {
		font-size: 56px;
	}
}

h3,
.heading-3 {
	font-size: 48px;

	@include responsive("small") {
		font-size: 32px;
	}
}

h4,
.heading-4 {
	font-size: 36px;

	@include responsive("small") {
		font-size: 28px;
	}
}

h5,
.heading-5 {
	font-size: 30px;

	@include responsive("small") {
		font-size: 26px;
	}
}

h6,
.heading-6 {
	font-size: 24px;

	@include responsive("small") {
		font-size: 22px;
	}
}

.text-50 {
	font-size: 50px;
	line-height: 1.4;

	@include responsive("small") {
		font-size: 32px;
	}
}

.text-32 {
	font-size: 32px;
	line-height: 1.4;

	@include responsive("small") {
		font-size: 26px;
	}
}

.text-24 {
	font-size: 24px;
	line-height: 1.4;

	@include responsive("small") {
		font-size: 22px;
	}
}

.text-22 {
	font-size: 22px;
	line-height: 1.4;

	@include responsive("small") {
		font-size: 20px;
	}
}

.text-20 {
	font-size: 20px;
	line-height: 1.5;

	@include responsive("small") {
		font-size: 18px;
	}
}

.text-18 {
	font-size: 18px;
	line-height: 1.5;

	@include responsive("small") {
		font-size: 16px;
	}
}

.text-16 {
	font-size: 16px;
	line-height: 1.5;

	@include responsive("small") {
		font-size: 15px;
	}
}
.text-15 {
	font-size: 15px;
	line-height: 1.3;
}
