.gallery-slider-block {
	.section-head {
		max-width: 780px;
		margin: 0 auto;

		h2 {
			margin-bottom: 60px;
		}

		p {
			font-size: 24px;
			line-height: 1.4;
			@include font(gs, med, weight);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.owl-stage-outer {
		overflow: visible;
	}
}
.gallery-image {
	img {
		border-radius: 10px;
	}
}
