.s-270 {
	height: 270px;

	@include responsive("small") {
		height: 120px;
	}

	@include responsive("tab") {
		height: 200px;
	}

	@include responsive("medium") {
		height: 200px;
	}

}

.s-200 {
	height: 200px;

	@include responsive("small") {
		height: 100px;
	}

	@include responsive("tab") {
		height: 150px;
	}

	@include responsive("medium") {
		height: 150px;
	}
}

.s-160 {
	height: 160px;

	@include responsive("small") {
		height: 86px;
	}

	@include responsive("tab") {
		height: 120px;
	}

	@include responsive("medium") {
		height: 120px;
	}
}

.s-140 {
	height: 140px;

	@include responsive("small") {
		height: 74px;
	}

	@include responsive("tab") {
		height: 100px;
	}

	@include responsive("medium") {
		height: 100px;
	}
}

.s-120 {
	height: 120px;

	@include responsive("small") {
		height: 64px;
	}

	@include responsive("tab") {
		height: 90px;
	}

	@include responsive("medium") {
		height: 90px;
	}
}

.s-100 {
	height: 100px;

	@include responsive("small") {
		height: 56px;
	}

	@include responsive("tab") {
		height: 76px;
	}

	@include responsive("medium") {
		height: 76px;
	}
}

.s-80 {
	height: 80px;

	@include responsive("small") {
		height: 46px;
	}

	@include responsive("tab") {
		height: 60px;
	}

	@include responsive("medium") {
		height: 60px;
	}
}

.s-60 {
	height: 60px;

	@include responsive("small") {
		height: 40px;
	}

	@include responsive("tab") {
		height: 50px;
	}

	@include responsive("medium") {
		height: 50px;
	}
}

.s-50 {
	height: 50px;

	@include responsive("small") {
		height: 36px;
	}

	@include responsive("tab") {
		height: 40px;
	}

	@include responsive("medium") {
		height: 40px;
	}
}

.s-40 {
	height: 40px;

	@include responsive("small") {
		height: 30px;
	}

	@include responsive("tab") {
		height: 36px;
	}

	@include responsive("medium") {
		height: 36px;
	}
}

.s-30 {
	height: 30px;

	@include responsive("small") {
		height: 24px;
	}
}
