/*-------------------------------------------------
>> Basic styles for Scrolling-block
-------------------------------------------------*/
@import "lead-paragraph-block";

/*-------------------------------------------------
>> Basic styles for Showroom Block
-------------------------------------------------*/
@import "showreem-block";

/*-------------------------------------------------
>> Basic styles for Videos Meetings Block
-------------------------------------------------*/
@import "video-meeting-block";

/*-------------------------------------------------
>> Basic styles for ScreenCast Block
-------------------------------------------------*/
@import "screencast-block";

/*-------------------------------------------------
>> Basic styles for AI Features Block
-------------------------------------------------*/
@import "ai-features-block";

/*-------------------------------------------------
>> Basic styles for Extensions Block
-------------------------------------------------*/
@import "extensions-block";

/*-------------------------------------------------
>> Basic styles for Image Alongside Text Block
-------------------------------------------------*/
@import "testimonials-block";

/*-------------------------------------------------
>> Basic styles for Pricing Block
-------------------------------------------------*/
@import "pricing-block";

/*-------------------------------------------------
>> Basic styles for Enterprise Block
-------------------------------------------------*/
@import "enterprise-block";

/*-------------------------------------------------
>> Basic styles for List block
-------------------------------------------------*/
@import "list-block";

/*-------------------------------------------------
>> Basic styles for Image Alongside Text
-------------------------------------------------*/
@import "iat-block";

/*-------------------------------------------------
>> Basic styles for Icon Tile
-------------------------------------------------*/
@import "icon-tiles";

/*-------------------------------------------------
>> Basic styles for MidPage CTA
-------------------------------------------------*/
@import "midpage-cta";

/*-------------------------------------------------
>> Basic styles for Image With Discription
-------------------------------------------------*/
@import "iwd-block";

/*-------------------------------------------------
>> Basic styles for Feature Video Block
-------------------------------------------------*/
@import "feature-video-block";

/*-------------------------------------------------
>> Basic styles for Gallery Slider
-------------------------------------------------*/
@import "gallery-slider";

/*-------------------------------------------------
>> Basic styles for FAQ's Block
-------------------------------------------------*/
@import "faq-block";

/*-------------------------------------------------
>> Basic styles for Quote Block
-------------------------------------------------*/
@import "quote-block";

/*-------------------------------------------------
>> Basic styles for Icon With Text Block
-------------------------------------------------*/
@import "icon-with-text-block";

/*-------------------------------------------------
>> Basic styles for Tabs with videos
-------------------------------------------------*/
@import "tab-video-block";
