.icon-with-text-block {
	&.three-columns {
		gap: 60px 54px;

		.column {
			display: flex;
			gap: 20px;
			width: calc(33.33% - 36px);
		}
	}
}

.icon-box-content {
	width: calc(100% - 92px);

	.text-24 {
		line-height: 1.05;
		padding: 20px 0;
		margin-bottom: 0;
	}

	p {
		font-size: 18px;
		@include font(gs, med, weight);

		&:last-child {
			margin-bottom: 0;
		}
	}
}
