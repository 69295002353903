.extension-block {

	.section-head {
		max-width: 1180px;
		width: 90%;
		margin: 0 auto;

		h2 {

			@include font(gs, med, weight);
			margin-bottom: 60px;
		}

		p {
			font-size: 24px;
			max-width: 780px;
			@include font(gs, med, weight);

			&:last-of-type {
				margin-bottom: 0px;
			}
		}
	}

	.block-button {
		max-width: 1180px;
		width: 90%;
		margin: 80px auto 0 auto;
	}
}

.extensions-slider {
	display: flex;

	.owl-stage {
		padding-left: 0 !important;
	}

	.owl-stage-outer {
		overflow: visible;
	}
}

.extension-box {

	background-color: map-get($map: $colors, $key: white10);
	border-radius: 10px;
	margin-right: 31px;
	width: 373px;

	.extension-content {
		padding: 30px 30px 40px 30px;

		@include responsive('small') {
			padding: 25px 18px 30px 19px;
		}
	}

	h3 {
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 0;
		font-size: 18px;
	}

	a,
	a:visited {
		display: inline-block;
		margin-top: 30px;
	}
}

.icon-area {
	line-height: 0.65;
	border-radius: 10px 10px 0 0;
	position: relative;

	img {
		border-radius: 10px 10px 0 0;
		width: 100%;

	}
}

.tp-price-month {
	position: absolute;
	right: 0;
	bottom: 14px;
	border-radius: 5px 0 0 5px;
	background-color: map-get($map: $colors, $key: blue);
	color: map-get($map: $colors, $key: white);
	@include font(gs, sbold, weight);
	font-size: 22px;
	line-height: 1.3;
	padding: 4px 10px 5px 10px;
}

.gradient-line {
	background-image: url(../images/gradient-line.png);
	background-repeat: no-repeat;
	height: 5px;
	background-size: cover;
}

.extension-tag {
	line-height: 1.3;
	padding: 3px 8px 3px 8px;
	@include font(gs, sbold, weight);
	letter-spacing: 0.9px;
	color: map-get($map: $colors, $key: white);
	text-transform: uppercase;
	border-radius: 5px;
	margin-bottom: 16px;
	display: inline-block;

	&.pro {
		background-color: map-get($map: $colors, $key: purple);
	}

	&.business {
		background-color: map-get($map: $colors, $key: magenta);
	}

	&.basic {
		background-color: map-get($map: $colors, $key: green);
	}

	&.party-tag {
		background-color: map-get($map: $colors, $key: yellow);
	}
}

a,
a:visited {
	&.show-demo {
		background-image: url(../images/play-button.svg);
		background-repeat: no-repeat;
		padding: 5px 0 4px 48px;
		border-bottom: 0px;
		font-size: 18px;
		line-height: 1.3;
		@include font(gs, med, weight);
		color: map-get($map: $colors, $key: white80);

		&:hover {
			background-image: url(../images/play-button-hover.svg);
			color: map-get($map: $colors, $key: white);
		}
	}
}

.text-single {
	position: relative;
	padding: 0;
	display: flex;
	margin: 0;
	will-change: transform;
}


.extension-tiles-block {
	.section-head {
		max-width: 100%;
		width: 100%;
	}
}

.extensions-tiles {
	&.three-columns {
		gap: 30px;

		.column {
			width: calc(33.33% - 20px);
			margin-right: 0;
		}
	}
}
