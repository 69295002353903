.feature-video-area {
	position: relative;
	border-radius: 10px;
	line-height: 0.65;

	video {
		line-height: 0.65;
		border-radius: 10px;
		margin-bottom: 0;
		cursor: pointer;
	}

	img {
		border-radius: 10px;
		position: absolute;
		z-index: 1;
		height: 100%;
	}

	&.active {

		.play-btn, .play-button,
		img {
			opacity: 0;
			visibility: hidden;
		}
	}
}
