.quote-block-heading {
	padding: 78px 0 0 0;
	background-image: url(../images/left-quote-icon.svg);
	background-repeat: no-repeat;
	background-position: top center;
}

.quote-block-heading {
	h2 {
		margin-bottom: 40px;
	}
}

.quote-content {
	p {
		font-size: 32px;
		margin-bottom: 40px;
		line-height: 1.4;
		@include font(gs, med, weight);
	}
}

.quote-client-image {
	margin-bottom: 20px;
	line-height: 0.65;

	img {
		max-width: 100px;
		max-height: 100px;
	}
}

.quote-client-name {
	color: map-get($map: $colors, $key: white);
	line-height: 1.3;
	@include font(gs, sbold, weight);

	span {
		display: block;
		font-size: 20px;
		@include font(gs, reg, weight);
		margin-top: 8px;

	}
}
