.image-with-discription-ctn {
	background-color: map-get($map: $colors, $key: l-black);
	padding: 80px 40px 40px 40px;

	.section-head {
		max-width: 950px;
		margin: 0 auto;

		h2 {
			margin-bottom: 40px;
		}

		p {
			font-size: 24px;
			line-height: 1.4;
			@include font(gs, med, weight);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.iwd-image {
	line-height: 0.65;

	img {
		border-radius: 10px;
	}
}
