/*-------------------------------------------------
>> Styles for tables
-------------------------------------------------*/
@import "body";

/*-------------------------------------------------
>> Styles for links
-------------------------------------------------*/
@import "links";

/*-------------------------------------------------
>> Styles for images and figures
-------------------------------------------------*/
@import "images";

/*-------------------------------------------------
>> Styles for lists
-------------------------------------------------*/
@import "lists";

/*-------------------------------------------------
>> Styles for quotes
-------------------------------------------------*/
@import "quotes";

/*-------------------------------------------------
>> Styles for tables
-------------------------------------------------*/
@import "tables";

/*-------------------------------------------------
>> Styles for frames and embeds
-------------------------------------------------*/
@import "embeds";
