@use "sass:math";

/*
Rem output with px fallback
*/
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 10) * 1px;
	font-size: $sizeValue * 10px;
}

/*
Rem output with px fallback with !important
*/
@mixin font-size-imp($sizeValue: 1) {
	font-size: ($sizeValue * 10) * 1px !important;
	font-size: $sizeValue * 10px !important;
}

/*
Column width with margin
*/
@mixin column($numberColumns, $gap) {
	width: math.div(100%, $numberColumns) - math.div(($gap * ($numberColumns - 1)), $numberColumns);
	margin-right: $gap;


	@if $numberColumns == 2 {

		&:nth-child(even) {
			margin-right: 0;
		}
	} @else {
		&:nth-child(#{$numberColumns}n + #{$numberColumns}) {
			margin-right: 0;
		}
	}
}

/*
Mixin for transition
*/
@mixin transition($property, $duration, $animation) {
	transition: $property $duration $animation;
}

@mixin background-image($image, $background_position, $background_size, $background_repeat, $background_height, $background_width) {

	@if $image != "" {
		background-image: url("../images/#{$image}");
	}

	background-repeat: $background_repeat;
	background-position: $background_position;
	background-size: $background_size;
	height: $background_height;
	width: $background_width;
}

@mixin responsive($breakpoint) {

	@if $breakpoint == "mini" {

		@media (max-width: 374px) {
			@content;
		}
	} @else if $breakpoint == "xssmall" {

		@media (max-width: 459px) {
			@content;
		}
	} @else if $breakpoint == "medsmall" {

		@media (min-width: 460px) and (max-width: 747px) {
			@content;
		}
	} @else if $breakpoint == "small" {

		@media (max-width: 747px) {
			@content;
		}
	} @else if $breakpoint == "tab" {

		@media (min-width: 748px) and (max-width: 1003px) {
			@content;
		}
	} @else if $breakpoint == "tabfull" {

		@media (max-width: 1003px) {
			@content;
		}
	} @else if $breakpoint == "medium" {

		@media (min-width: 1004px) and (max-width: 1179px) {
			@content;
		}
	} @else if $breakpoint == "mediumfull" {

		@media (max-width: 1179px) {
			@content;
		}
	} @else if $breakpoint == "screen" {

		@media (min-width: 1180px) and (max-width: 1345px) {
			@content;
		}
	} @else if $breakpoint == "screenfull" {

		@media (max-width: 1345px) {
			@content;
		}
	} @else if $breakpoint == "large" {

		@media (min-width: 1346px) and (max-width: 1679px) {
			@content;
		}
	}
}
