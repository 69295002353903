.iat-block-ctn {
	&.with-box {
		padding: 40px 60px 40px 40px;
		background-color: map-get($map: $colors, $key: l-black);
		border-radius: 10px;
		box-sizing: border-box;

	}

	&.image-at-left {
		.iat-block {
			flex-direction: row-reverse;
		}
	}

	&.simple {
		.two-columns {
			align-items: center;
			justify-content: center;
		}

		.iat-content-area {
			padding-top: 0;
			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.iat-block {

	&.two-columns {
		gap: 60px;

		.column {
			width: calc(50% - 30px);
		}
	}


}

.iat-content-area {
	padding-top: 30px;

	h2 {
		margin-bottom: 30px;
	}

	p {
		line-height: 1.4;
		font-size: 22px;
		@include font(gs, med, weight);
		margin-bottom: 40px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			line-height: 1.4;
			font-size: 22px;
			@include font(gs, med, weight);
			margin-bottom: 25px;
			padding-left: 48px;
			padding-bottom: 3px;
			background-image: url(../images/iat-list-icon.svg);
			background-position: left top;
			background-repeat: no-repeat;

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				display: none;
			}
		}
	}
}

.iat-imaga-area {
	line-height: 0.65;

	img {
		border-radius: 10px;
	}
}
