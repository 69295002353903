.testimonials-block {

	.section-head {

		h2 {

			@include font(gs, med, weight);
			margin-bottom: 0;
		}
	}
}

.single-testimonial {
	background-color: map-get($map: $colors, $key: l-gray);
	border: 1px solid map-get($map: $colors, $key: white20);
	border-radius: 10px;
	padding: 20px 20px 30px 20px;
	margin-bottom: 30px;
	color: map-get($map: $colors, $key: dgray);
}

.testi-content {

	p {
		color: map-get($map: $colors, $key: dgray);
		margin-bottom: 40px;
		font-size: 18px;


	}
}

.testimonials-area {
	justify-content: space-between;
	-webkit-mask-image: linear-gradient(var(--mask-direction, to bottom), hsla(0deg, 0%, 0%, 0), hsl(0deg, 0%, 0%) 0%, hsl(0deg, 0%, 0%) 55%, hsla(0deg, 0%, 0%, 0));
	overflow: hidden;
	flex-wrap: wrap;
	align-items: flex-start;
	height: 810px;
}

.testimonial-column {
	width: 31.6%;
}

.client-image {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	margin-right: 16px;
}

.client-designation {

	span {

		@include font(gs, med, weight);
		font-size: 18px;
		display: block;
		margin-bottom: 8px;
		line-height: 1.3;
		color: map-get($map: $colors, $key: dgray);
	}

	p {
		font-size: 18px;
		line-height: 1.3;
		margin-bottom: 0;
		color: map-get($map: $colors, $key: dgray);
	}
}
