.list-block {
	&.three-columns {
		gap: 30px 56px;
	}

	.column {
		width: calc(33.33% - 37.33px);
		&:last-child {
			.list-number {
				&::after {
					display: none;
				}
			}
		}
	}
}

.list-number {
	position: relative;

	span {
		width: 72px;
		height: 72px;
		border-radius: 50%;
		background-color: map-get($map: $colors, $key: blue);
		color: map-get($map: $colors, $key: white);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 32px;
		@include font(gs, med, weight);
		margin-bottom: 40px;
		line-height: 1.3;
	}

	&::after {
		content: "";
		background-color: map-get($map: $colors, $key: white50);
		height: 1px;
		position: absolute;
		top: calc(50% - 1px);
		width: calc(100% - 132px);
		right: 0;

	}
}

.list-block-heading {
	color: map-get($map: $colors, $key: white);
	@include font(gs, med, weight);
	margin-bottom: 20px;
	line-height: 1.3;
}

.list-block-content {
	p {
		line-height: 1.4;
		font-size: 22px;
		@include font(gs, med, weight);
		max-width: 353px;
		color: map-get($map: $colors, $key: white50);

		&:last-child {
			margin-bottom: 0;
		}
	}
}
