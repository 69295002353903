blockquote {

	&.wp-block-quote {

		&.is-style-large {
			border-left: 0;
			padding: 0;
		}
	}
}
