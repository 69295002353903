.video-meeting-block {

	.section-head {

		h2 {
			padding-left: 102px;
			background-image: url(../images/video-icon-big.svg);
			background-repeat: no-repeat;
			background-size: 72px;
			background-position: top 5px left;
			margin-bottom: 0;
		}
	}
}

.videos-ctn {
	flex-wrap: wrap;
	justify-content: space-between;
}

.video-box {
	background-color: map-get($map: $colors, $key: l-black);
	border-radius: 10px;
	justify-content: space-between;
	margin-top: 20px;

	&:first-child {
		margin-top: 0;
		width: 100%;
	}

	&:nth-child(2) {
		width: 680px;
		display: block !important;

		.animation-box {
			padding: 0 40px 0 0;
		}

		.lottie-box {
			max-width: 567px;
		}
	}

	&:nth-child(3) {
		width: 480px;
		display: block !important;

		.animation-box {
			padding: 0 0 40px 40px;
		}

		.lottie-box {
			max-width: 440px;
		}
	}
}

.content-box {
	padding: 40px 0 68px 40px;
	max-width: 465px;
	box-sizing: border-box;

	p {

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.video-title {
	color: map-get($map: $colors, $key: white);

	@include font(gs, med, weight);
	margin-bottom: 20px;
}

.animation-box {
	padding: 50px 30px 50px 50px;
	position: relative;
}

.lottie-box {
	max-width: 550px;
	line-height: 0.65;
}

.video-content {
	p {
		@include font(gs, med, weight);
	}
}

.videos-block-ctn {
	.video-box {
		padding: 40px;
		box-sizing: border-box;

		.block-video {
			line-height: 0.65;
		}

		.content-box {
			padding: 0;
		}

		&:nth-child(1) {
			.block-video {
				max-width: 540px;
				padding: 0;
				border-radius: 10px;

				img,
				video {
					border-radius: 10px;
				}
			}
		}

		&:nth-child(2),
		&:nth-child(3) {
			width: 49.153%;

			.block-video {
				max-width: 480px;
				padding: 0;
				margin: 0 0 -40px -40px;
				img, video {
					border-radius: 0 10px;
				}
			}
			.video-content {
				max-width: 440px;
				margin-bottom: 80px;
			}
		}
	}
}
