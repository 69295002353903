.wp-block-gallery .wp-block-image a {
	border-bottom: none;
}

.page-section {

	.wp-block-gallery ul li {
		padding: 0;

		&::before {
			display: none;
		}
	}
}
