.logos-area {
	display: flex;
	align-items: center;
}

.plus-icon {
	line-height: 0.65;
	margin: 0 20px;
}

.scrolling-logo {
	box-shadow: 0 3.08235px 38.5294px rgba(0, 0, 0, 0.1);
	line-height: 0.65;
}


.lead-text {

	span {
		transition: all 0.3s cubic-bezier(0.4, 0, 0.3, 1);

		@include font(gs, med, weight);
		color: map-get($map: $colors, $key: white10);
		display: inline;

		marker, strong {
			color: map-get($map: $colors, $key: white10);
			transition: all 0.3s cubic-bezier(0.4, 0, 0.3, 1);
		}

		&.active {
			color: map-get($map: $colors, $key: white);

			marker, strong {
				color: map-get($map: $colors, $key: blue);
			}
		}
	}

	mark {
		background-color: transparent;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.3, 1);
	}
}
