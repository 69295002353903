@use "sass:math";

@media (max-width: 1199px) {
	.header-logo {
		z-index: 10;
	}
	.header-wrapper {
		background-color: transparent;
		backdrop-filter: initial;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: map-get($map: $colors, $key: black);;
			z-index: 9;
			display: block;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in-out;
		}
	}

	.header-inner {
		padding: 19px 5%;
		border: none;
		width: 100%;
		border-radius: 0;
	}

	body {
		&.no-overflow {
			.header-wrapper {
				background-color: map-get($map: $colors, $key: black);
				backdrop-filter: initial;
				&::after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	header {
		top: 0;

		&.shrink {
			.header-wrapper {
				background-color: map-get($map: $colors, $key: black70);
				backdrop-filter: blur(30px);
			}
		}
	}

	.right-heade {
		width: 100%;
		float: none;
	}

	.logo {
		max-width: 190px;

		a {
			margin-top: 1px;
		}
	}

	.nav-overlay .header-nav {

		ul {
			margin: 0 0 120px 0;
			padding: 0;
			background: transparent;
			width: 100%;

			li {
				padding: 0;
				margin: 0 0 20px;
				position: relative;
				display: block;
				opacity: 1;
				font-size: 32px;
				line-height: 1.4;

				a,
				a:visited {
					color: map-get($map: $colors, $key: white);
					line-height: 1;
					display: inline-block;
					border-bottom: none;
					position: relative;
					padding: 0;

					&::after {
						display: none;
					}
				}

				&.current-menu-item>a,
				&.current-menu-parent>a,
				a:hover {
					border-bottom: none;
				}
			}

			ul {
				transition: none;
				display: none;
				padding: 0;
				margin: 20px 0 30px 0;
				position: relative;
				top: initial;
				left: initial;
				opacity: initial;
				visibility: initial;
				overflow: visible;
				box-shadow: none;

				&::after {
					display: none;
				}

				li {
					margin: 0 0 16px;
					background-size: 36px;

					&:last-child {
						margin-bottom: 0;
					}

					a,
					a:visited {
						padding: 11px 0 11px 54px;
						text-transform: inherit;
						display: block;
						@include font(gs, reg);
						font-size: 16px;
						color: map-get($map: $colors, $key: white80);
					}

					&.current-menu-item>a,
					&.current-menu-parent>a {
						color: map-get($map: $base_colors, $key: mobile_nav_sub_link_color);
					}
				}

				ul {
					padding: 20px 0 10px 20px;
				}
			}
		}
	}

	.header-nav ul li.menu-item-has-children>a {
		background-image: none;
		padding-right: 0;
	}

	.nav-overlay {
		position: fixed;
		background: map-get($map: $colors, $key: dgray) linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		height: 100vh;
		opacity: 0;
		visibility: hidden;
		overflow: visible;
		box-sizing: border-box;
		z-index: 8;
		padding-top: 106px;

		@include transition(all, 0.3s, ease-in-out);

		.nav-container {
			display: block;
		}
	}

	.logged-in.admin-bar {

		.nav-overlay {
			top: 110px;
		}
	}

	.nav-overlay.open {
		opacity: 1;
		visibility: visible;
		overflow-y: scroll;
	}


	header .nav-container {

		@include transition(all, 0.4s, ease-in-out);
		opacity: 0;
		padding: 0 0 30px 0;
		transition-delay: 0.25s;
		box-sizing: border-box;
		width: 90%;
		margin: 0 auto;

		.header-nav {
			text-align: left;
		}
	}

	header .nav-overlay.open .nav-container {
		opacity: 1;
		display: block;
	}


	header.shrink .nav-overlay.open .nav-container {
		opacity: 1;
	}

	.nav-content {
		height: 100%;
		max-height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		box-sizing: border-box;
	}

	.nav-content::-webkit-scrollbar-track {
		box-shadow: none;
		background-color: transparent;
	}

	.nav-content::-webkit-scrollbar {
		width: 3px;
		background-color: transparent;
		position: relative;
		left: 5px;
		box-shadow: none;
	}

	.nav-content::-webkit-scrollbar-thumb {
		box-shadow: none;
		border-radius: 2px;
	}

	.desktop-hide {
		display: block !important;
	}

	.header-btns.mobile-hide {
		display: none;
	}

	.header-btns.desktop-hide {
		margin-top: 30px;
		top: 0;
		opacity: 1;
		max-width: 500px;
		margin: 70px auto 0 auto;


		a,
		a:visited {
			display: block;
			text-align: center;
			margin-right: 0;

			&.button,
			&.login-btn {
				padding: 15px 20px 16px 20px;
			}

			&:first-child {
				margin-bottom: 18px;
			}
		}
	}

	.menu-btn {
		top: -7px;
		right: 0;
		height: 20px;
		width: 26px;
		cursor: pointer;
		z-index: 9999;
		display: inline-flex;
		flex-direction: column;
		position: relative;
		justify-content: space-between;

		@include transition(all, 0.3s, ease-in-out);

		span {
			height: 2px;
			width: 100%;
			background-color: map-get($map: $colors, $key: white);
			opacity: 1;

			@include transition(all, 0.3s, ease-in-out);
		}

		&.active span {

			&:first-child {
				transform: translateY(10px) translateX(0) rotate(-45deg);
			}

			&:last-child {
				transform: translateY(-8px) translateX(0) rotate(45deg);
			}

			&.middle {
				opacity: 0;
			}
		}
	}

	li.menu-item-has-children {

		.submenu-icon {
			z-index: 9999;
			position: relative;
			top: 1px;
			display: inline-block;
			margin-left: 9px;
			height: 22px;
			width: 22px;
			cursor: pointer;
			background-position: center bottom;
			background-repeat: no-repeat;
			background-image: url(../images/submenu-icon-white.svg);
		}

		&.active {
			.submenu-icon {
				transform: rotate(180deg);
			}
		}
	}
}


.no-overflow .logged-in.admin-bar .nav-overlay {
	top: 32px;
	height: calc(100vh - 32px);
}

@media (max-width: 782px) {

	.no-overflow .logged-in.admin-bar .left-header {
		top: 110px;
	}

	.no-overflow .logged-in.admin-bar .nav-overlay {
		top: 109px;
		height: calc(100vh - 46px);
	}

	#wpadminbar {
		position: fixed !important;
	}

	body.logged-in.admin-bar {

		header {
			top: 46px;
		}

		&.hide-topbar {

			header {
				top: 2px;
			}
		}

		&.have-topbar {

			.hero-section {
				padding-top: 113px;
			}
		}

		.hero-section {
			padding-top: 69px;
		}
	}
}
