/****
 *
 * helper classes
 *
****/

.large-text {
	font-size: 26px;
	line-height: 1.3;
}

.small-text {
	font-size: 14px;
	line-height: 1;
}

.black-text {
	color: map-get($map: $base_colors, $key: pure_black);
}

.white_text {
	color: map-get($map: $base_colors, $key: pure_white);
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.center-align {
	text-align: center;
}

.right-align {
	text-align: right;
}

.left-align {
	text-align: left;
}

.text-uppercase {
	text-transform: uppercase;
}

// Text-decuration none
.text-decuration-none {
	text-decoration: none;
}

// flex

.d-flex {
	display: flex;
}

.d-inline-flex {
	display: inline-flex;
}

.flex-row {
	flex-direction: row;
}

.flex-column {
	flex-direction: column;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

.flex-column-reverse {
	flex-direction: column-reverse;
}

.justify-content-start {
	justify-content: flex-start;
}

.justify-content-end {
	justify-content: flex-end;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-between {
	justify-content: space-between;
}

.justify-content-around {
	justify-content: space-around;
}

.align-items-start {
	align-items: flex-start;
}

.align-items-end {
	align-items: flex-end;
}

.align-items-center {
	align-items: center;
}

.align-items-baseline {
	align-items: baseline;
}

.align-items-stretch {
	align-items: stretch;
}

.align-content-start {
	align-content: flex-start;
}

.align-content-end {
	align-content: flex-end;
}

.align-content-center {
	align-content: center;
}

.align-content-between {
	align-content: space-between;
}

.align-content-around {
	align-content: space-around;
}

.align-content-stretch {
	align-content: stretch;
}

// flex items
.align-self-auto {
	align-self: auto;
}

.align-self-start {
	align-self: flex-start;
}

.align-self-end {
	align-self: flex-end;
}

.align-self-center {
	align-self: center;
}

.align-self-baseline {
	align-self: baseline;
}

.align-self-stretch {
	align-self: stretch;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-no-wrap {
	flex-wrap: nowrap;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// background
.bg-cover {
	background-size: cover;
}

.bg-contain {
	background-size: contain;
}

.bg-position-center {
	background-position: center;
}

.bg-norepeat {
	background-repeat: no-repeat;
}

.bg-repeat {
	background-repeat: repeat;
}

.reset-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

/****
 *
 * breadcrumbs
 *
****/
#breadcrumbs {
	color: map-get($map: $base_colors, $key: breadcrumb_color);
	text-transform: uppercase;
	margin-bottom: 23px;
	font-size: 14px;

	span {
		font-size: 18px;

		&.breadcrumb_last {
			font-size: 14px;
			font-weight: 500;
			margin-left: 10px;
		}
	}

	a,
	&:visited {
		color: map-get($map: $base_colors, $key: breadcrumb_link_color);
		font-weight: 700;
		margin: 0 10px;
		border-bottom: 1px solid
			map-get($map: $base_colors, $key: breadcrumb_border_color);
		font-size: 14px;

		&:hover {
			border-bottom: 1px solid
				map-get($map: $base_colors, $key: breadcrumb_border_color_hover);
		}
	}
}

.single-post #breadcrumbs span span span span span span {
	color: map-get($map: $base_colors, $key: breadcrumb_span_color);
}

.single-post #breadcrumbs span span span span span span a {
	color: map-get($map: $base_colors, $key: breadcrumb_link_color);
}

/****
 *
 * buttons
 *
****/
.learn-more {
	color: map-get($map: $colors, $key: pure_black);

	@include font(gs, med);
}

a,
a:visited,
span {
	&.button {
		box-sizing: border-box;
		position: relative;
		display: inline-block;
		overflow: hidden;
		padding: 19px 72px;
		text-decoration: none;
		color: map-get($map: $colors, $key: white);
		background-color: map-get($map: $colors, $key: blue);
		transition: all 0.35s ease-in-out;
		text-align: center;
		border-radius: 8px;
		line-height: 1.3;
		margin-right: 12px;
		border-bottom: 0;

		@include font(gs, med, weight);

		font-size: 20px;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: map-get($map: $colors, $key: d-blue);
			color: map-get($map: $colors, $key: white);
		}

		&.learn-more {
			background-color: map-get($map: $colors, $key: m-gray);

			&::after {
				content: "";
				width: 20px;
				height: 20px;
				background-image: url(../images/learn-more-icon.svg);
				background-repeat: no-repeat;
				background-position: center center;
				margin-left: 10px;
				position: relative;
				top: 3px;
				display: inline-block;
			}

			&:hover {
				background-color: map-get($map: $colors, $key: d-gray);
			}
		}
		&.small-btn {
			padding: 13px 36px;
			font-size: 18px;
		}
	}

	&.read-more {
		color: map-get($map: $colors, $key: white80);

		@include font(gs, med, weight);

		font-size: 18px;
		border-bottom: 1px solid transparent;
		line-height: 1.3;

		&:hover {
			color: map-get($map: $colors, $key: white);
			border-bottom: 1px solid map-get($map: $colors, $key: white);
		}
	}
}

a.wp-block-button__link,
a.wp-block-button__link:visited {
	box-sizing: border-box;
	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: 11px 30px;
	text-decoration: none;
	color: map-get($map: $base_colors, $key: theme_btn_color);
	background-color: map-get($map: $base_colors, $key: theme_btn_bgcolor);
	border: 2px solid map-get($map: $base_colors, $key: theme_btn_border_color);
	transition: all 0.35s ease-in-out;
	margin-right: 9px;

	@include font(gs, reg);

	font-size: 16px;
	border-radius: 100px;

	&:hover {
		background-color: map-get(
			$map: $base_colors,
			$key: theme_btn_bgcolor_hover
		);
		border: 2px solid
			map-get($map: $base_colors, $key: theme_btn_border_color_hover);
		color: map-get($map: $base_colors, $key: theme_btn_color_hover);
	}

	&.has-background {
		border: none;

		&:hover {
			border: none;
			color: unset;
		}
	}

	&.has-text-color.has-background {
		border: none;

		&:hover {
			border: none;
		}
	}
}

/****
 *
 * WordPress buttons
 *
****/

// a.wp-block-button__link,
// a.wp-block-button__link:visited,
// .wp-block-button__link {

// 	&:hover {
// 		border: 2px solid map-get($map: $base_colors, $key: wp_btn_border_color);
// 		color: map-get($map: $base_colors, $key: wp_btn_color);
// 		background-color: map-get($map: $base_colors, $key: wp_btn_bgcolor);
// 	}
// }

.wp-block-button.is-style-outline {
	a.wp-block-button__link,
	a.wp-block-button__link:visited {
		border: 2px solid
			map-get($map: $base_colors, $key: wp_outline_btn_border_color);

		&:hover {
			background-color: map-get(
				$map: $base_colors,
				$key: wp_outline_btn_bgcolor
			);
			color: map-get($map: $base_colors, $key: wp_outline_btn_color);
		}

		&.has-text-color,
		&.has-background {
			&:hover {
				background-color: unset;
				color: unset;
			}
		}

		&.has-text-color.has-background {
			border: none;

			&:hover {
				border: none;
			}
		}
	}
}

.no-visible {
	visibility: hidden;
}

/****
 *
 * 404 page
 *
****/
.not-found {
	text-align: center;
}

.error {
	text-align: center;
	margin: 20px 0;
	width: 100%;

	.menu {
		margin: 0;
	}

	ul li {
		display: inline-block;
		margin: 0 0 0 20px;
		font-weight: 700;
		padding: 0;

		&::before {
			display: none;
		}

		a {
			padding: 10px;
			border-bottom: 0;

			&:hover {
				color: map-get($map: $base_colors, $key: error_menu_color);
			}
		}

		ul {
			display: none;
		}
	}
}

.form-404 {
	margin: 50px auto 0;
	max-width: 720px;

	p {
		margin-bottom: 20px;
	}
}

.overflow-hidden {
	overflow: hidden;
}

.block-button {
	margin-top: 80px;
	line-height: 0.65;
}

.jump-position {
	position: relative;
	top: -100px;
}

.text-block {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 30px;
	}

	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.hide {
	display: none;
}
.rd-10 {
	border-radius: 10px;
}
.hide-for-now {
	display: none;
}
