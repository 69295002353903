.tabs-area {
	.feature-video-area {
		height: 100%;
		border-radius: 0 10px 10px 0;

		img {
			border-radius: 0 10px 10px 0;
			aspect-ratio: 1/1;
			object-fit: cover;
			width: 100%;
		}

		video {
			border-radius: 0 10px 10px 0;
			height: 100%;
			object-fit: cover;
		}
	}
}

.tab-link-ctn {

	a,
	a:visited {
		gap: 16px;
		display: flex;
		border-radius: 10px;
		padding: 15px;
		border-bottom: 0;
		color: map-get($map: $colors, $key: white);

		&:hover {
			color: map-get($map: $colors, $key: blue);

		}

		&.current {
			background-color: map-get($map: $colors, $key: white10);
			color: map-get($map: $colors, $key: white);

			&:hover {
				color: map-get($map: $colors, $key: white);
			}
		}
	}
}

.video-title-area {
	width: calc(100% - 121px);

	.text-18 {
		line-height: 1.3;
		margin-bottom: 12px;
		@include font(gs, med, weight);
	}
}

.video-time {
	line-height: 1.3;
	font-size: 16px;
	color: map-get($map: $colors, $key: white50);
}

.left-column {
	background-color: map-get($map: $colors, $key: black);

	box-sizing: border-box;
	border-radius: 10px 0 0 10px;
	height: 590px;
	width: 400px;
	overflow: hidden;
	overflow-y: scroll;

	&::-webkit-scrollbar-track {
		background-color: map-get($map: $colors, $key: white20);
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: map-get($map: $colors, $key: white20);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: map-get($map: $colors, $key: white);
	}
}

.tab-link-ctn {
	padding: 5px;
	border-bottom: 1px solid map-get($map: $colors, $key: white20);

	&:last-child {
		border-bottom: 0;
	}
}

.list-wrap {
	height: 100% !important;
}

.right-column {
	width: 780px;
}

.main-video {
	height: 100%;
}

.tab-video-thumb {
	line-height: 0.65;
	width: 105px;
	border-radius: 10px;

	img {
		border-radius: 10px;
		aspect-ratio: 1/0.762;
		object-fit: cover;
	}
}
