.extensions-tiles-ctn {
	width: calc(100% + 30px);
	margin-left: -13px;

	@include responsive("small") {
		width: 100%;
		margin-left: 0;
	}

	&.three-columns {
		gap: 30px;

		.column {
			width: calc(33.33% - 31px);

			@include responsive("small") {
				width: 100%;
			}
		}
	}

	.extension-box {
		margin: 0 15px 3.25% 15px;

		@include responsive("small") {
			margin: 24px 0 0 0;

			&:first-child {
				margin-top: 0;
			}
		}
	}
}

.filters-button-group {
	display: flex;
	gap: 16px;
	margin-top: 13px;

	@include responsive("small") {
		margin-top: 30px;
		white-space: nowrap;
		display: block;
		overflow-x: scroll;
		width: 105.5%;
		margin-left: 0;
		padding-right: 20px;
		box-sizing: border-box;
	}

	button.button {
		border-radius: 8px;
		font-size: 18px;
		line-height: 1.3;
		height: 50px;
		box-sizing: border-box;
		max-width: max-content;
		overflow: hidden;
		position: relative;
		padding: 13px 20px 11px 20px;
		background-color: map-get($map: $colors, $key: white10);

		&:hover {
			background-color: map-get($map: $colors, $key: white20);
		}

		@include responsive("small") {
			height: 44px;
			font-size: 16px;
			padding: 9px 16px 11px 16px;
			margin-right: 10px;
		}

		&::after {
			height: 8px;
			content: "";
			position: absolute;
			bottom: -5px;
			width: 100%;
			left: 0;
			border-radius: 0 0 10px 10px;
		}

		&.basic-btn {
			&::after {
				background-color: map-get($map: $colors, $key: green);
			}
		}

		&.pro-btn {
			&::after {
				background-color: map-get($map: $colors, $key: purple);
			}
		}

		&.business-btn {
			&::after {
				background-color: map-get($map: $colors, $key: magenta);
			}
		}

		&.party-btn {
			&::after {
				background-color: map-get($map: $colors, $key: yellow);
			}
		}
	}

	.button:active,
	.button.is-checked {
		background-color: map-get($map: $colors, $key: white);
		color: map-get($map: $colors, $key: dgray);

		&:hover {
			background-color: map-get($map: $colors, $key: white);
		}
	}
}

.extension-popup-ctn {
	max-width: 980px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 16px 16px 50px 16px;
	border-radius: 10px;
	background-color: map-get($map: $colors, $key: white);
	position: relative;
	width: 100%;
	margin: 120px 0 60px 0;
}

.extension-popup-video {
	border-radius: 10px;
	line-height: 0.65;

	video {
		border-radius: 10px;
		margin-bottom: 25px;
	}
}

.extension-popup-content {
	color: map-get($map: $colors, $key: dgray);
	padding: 0 14px;

	.extension-tag {
		padding: 3px 8px;
		font-size: 15px;
		@include font(gs, sbold, weight);
		color: map-get($map: $colors, $key: white);
		line-height: 1.3;
		letter-spacing: 0.9px;
		text-transform: uppercase;
		border-radius: 5px;

		&.green {
			background-color: map-get($map: $colors, $key: green);
		}
	}

	.text-32 {
		@include font(gs, med, weight);
		margin-bottom: 23px;
		line-height: 1.3;
	}

	p {

		margin-bottom: 20px;
	}

	ul {
		margin: 40px 0 0 0;

		li {
			margin-bottom: 20px;
			background-image: url(../images/popup-list-next-icon.svg);
			background-repeat: no-repeat;
			padding-left: 36px;
			background-position: left top 4px;
			display: block;

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				display: none;

			}
		}
	}
}
