/*-------------------------------------------------
>> Styles for header and footer of website
-------------------------------------------------*/
@import "layout/layout";

/*-------------------------------------------------
>> Basic styles for theme
-------------------------------------------------*/
@import "theme";

/*-------------------------------------------------
>> Basic styles for Extensions Page
-------------------------------------------------*/
@import "extensions-page";

/*-------------------------------------------------
>> Basic styles for Get In Touch
-------------------------------------------------*/
@import "get-in-touch";

/*-------------------------------------------------
>> Basic styles for Blog post
-------------------------------------------------*/
@import "columns";

/*-------------------------------------------------
>> Styles for Website Blocks
-------------------------------------------------*/
@import "blocks/blocks";
